import { Component, ViewEncapsulation } from '@angular/core';

import { User } from '@towncloud/thor-api';

import { CurrentUserResource } from 'resources';

@Component({
  templateUrl: './profile.html',
  styleUrls: ['./profile.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserProfileComponent {
  user: User = new User();

  constructor(private currentUserResource: CurrentUserResource) {
    this.currentUserResource.get().then(user => {
      this.user = user;
    });
  }
}
