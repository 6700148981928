import { Injectable, EventEmitter } from '@angular/core';
import { DataService } from 'services';

@Injectable()
export class CartService {
  cartItems: Array<any> = [];
  changeEvent = new EventEmitter();

  constructor() {
    if (sessionStorage.getItem('cart')) {
      this.cartItems = JSON.parse(sessionStorage.getItem('cart'));
    }
  }

  emitChangeEvent() {
    this.changeEvent.emit(this.cartItems);
  }

  change() {
    return this.changeEvent;
  }

  get() {
    return this.cartItems;
  }

  add(item) {
    this.cartItems.push(item);

    this.update();
  }

  remove(index) {
    this.cartItems.splice(index, 1);

    this.update();
  }

  update() {
    sessionStorage.setItem('cart', JSON.stringify(this.cartItems));

    this.emitChangeEvent();
  }

  clear() {
    this.cartItems = [];

    this.update();
  }
}
