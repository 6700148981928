import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./app.authguard";
import { TownGuard } from "./app.townguard";

import { UserProfileComponent } from "./views/profile/profile.component";
import { UserProfileInformationComponent } from "./views/profile/information/profileInformation.component";
import { UserProfileSettingsComponent } from "./views/profile/settings/profileSettings.component";
import { UserProfileResetPasswordComponent } from "./views/profile/resetPassword/profileResetPassword.component";

import { RootComponent } from "./views/root/root.component";
import { NoTownComponent } from './views/noTown/noTown.component';
import { LoginComponent } from "./views/login/login.component";

import { MyBusinessComponent } from "./views/myBusiness/myBusiness.component";
import { BusinessManagementComponent } from "./views/myBusiness/management/businessManagement.component";
import { BusinessInformationComponent } from "./views/myBusiness/detail/information/businessInformation.component";
import { BusinessDocumentsComponent } from "./views/myBusiness/detail/documents/businessDocuments.component";
import { AddDocumentComponent } from "./views/myBusiness/detail/documents/create/addDocument.component";
import { BusinessLicensesComponent } from "./views/myBusiness/detail/licenses/businessLicenses.component";
import { BusinessLicenseCertificateComponent } from "./views/myBusiness/detail/licenses/certificate/businessLicenseCertificate.component";
import { CreateLicenseWizardComponent } from "./views/myBusiness/detail/licenses/create/createLicenseWizard.component";
import { BusinessDetailContactsComponent } from "./views/myBusiness/detail/contacts/businessDetailContacts.component";
import { ReceiptComponent } from "./views/receipt/receipt.component";

import { AddBusinessComponent } from "./views/addBusiness/addBusiness.component";
import { BusinessPinComponent } from "./views/businessPin/businessPin.component";
import { CreateBusinessWizardComponent } from "./views/myBusiness/create/createBusinessWizard.component";

import { CartComponent } from "./views/cart/cart.component";
import { CheckoutComponent } from "./views/checkout/checkout.component";

import { DashboardComponent } from "./views/dashboard/dashboard.component";

import { ProcurementDashboardComponent } from "./views/procurement/procurement-dashboard/procurement-dashboard.component";
import { OpportunitiesComponent } from "./views/procurement/opportunities/opportunities.component";
import { OpportunityDetailComponent } from "./views/procurement/opportunityDetail/opportunityDetail.component";

const routes: Routes = [
  {
    path: "",
    component: RootComponent,
    canActivate: [TownGuard]
  },
  {
		path: "notown",
    component: NoTownComponent,
    canActivate: [TownGuard]
	},
  {
    path: ":town",
    canActivate: [TownGuard],
    children: [
      {
        path: "",
        component: DashboardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: "register",
        redirectTo: "login"
      },
      {
        path: "profile",
        component: UserProfileComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            redirectTo: "information",
            pathMatch: "prefix"
          },
          {
            path: "information",
            component: UserProfileInformationComponent
          },
          {
            path: "settings",
            component: UserProfileSettingsComponent
          },
          {
            path: "resetpassword",
            component: UserProfileResetPasswordComponent
          }
        ]
      },
      {
        path: "mybusiness",
        component: MyBusinessComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: BusinessManagementComponent
          },
          {
            path: "add",
            component: AddBusinessComponent
          },
          {
            path: "add/pin",
            component: BusinessPinComponent
          },
          {
            path: "add/create",
            component: CreateBusinessWizardComponent
          },
          {
            path: ":businessId",
            children: [
              {
                path: "",
                component: BusinessInformationComponent
              },
              {
                path: "documents",
                children: [
                  {
                    path: "",
                    component: BusinessDocumentsComponent
                  },
                  {
                    path: "add",
                    component: AddDocumentComponent
                  },
                ]
              },
                
              {
                path: "licenses",
                children: [
                  {
                    path: "",
                    component: BusinessLicensesComponent
                  },
                  {
                    path: "add",
                    component: CreateLicenseWizardComponent
                  },
                  {
                    path: ":licenseId/receipt",
                    component: ReceiptComponent
                  },
                  {
                    path: ":licenseId/certificate",
                    component: BusinessLicenseCertificateComponent
                  }
                ]
              },
              {
                path: "contacts",
                component: BusinessDetailContactsComponent
              }
            ]
          }
        ]
      },
      {
        path: "procurement",
        children: [
          {
            path: "",
            component: ProcurementDashboardComponent
          },
          {
            path: "opportunities",
            component: OpportunitiesComponent
          },
          {
            path: "opportunities/:opportunityId",
            component: OpportunityDetailComponent
          }
        ]
      },
      {
        path: "cart",
        component: CartComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "checkout",
        component: CheckoutComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  
];

export const appRouterModule = RouterModule.forRoot(routes);
