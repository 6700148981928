import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";

import {
  User,
  Application,
} from "@towncloud/thor-api";

import { CurrentTownService } from 'services';

@Injectable({
  providedIn: "root"
})
export class TownGuard implements CanActivate {
  town: any;

  constructor(
    private router: Router,
    private currentTownService: CurrentTownService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if(route.params.town){
      this.currentTownService.setTown(route.params.town);
      const town = await this.currentTownService.get();
      if(!town){
        this.navigate(route);
      }
    }else{
      this.navigate(route);
    }

    return true;
  }

  navigate(route){
    if(route.routeConfig.path.indexOf('notown') === -1){
        this.router.navigate(["notown"]);
      }
  }

}
