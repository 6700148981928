import { EventEmitter } from '@angular/core';
import { CurrentUserResource } from 'resources';

export class CurrentUserService {

	currentUserChange = new EventEmitter();

	constructor(){}

	emitChangeEvent(user) {
		this.currentUserChange.emit(user);
	}

	getChangeEmitter() {
		return this.currentUserChange;
	}
}