import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";

import { City, User, Application, ResourceParams } from "@towncloud/thor-api";

import { CurrentTownService, CurrentUserService } from "services";

import { ResourceParams as StarkResourceParams } from "dataTypes";

import { CurrentUserResource, CityApplicationsResource } from "resources";

@Component({
  selector: "tc-top-navigation",
  templateUrl: "./top-navigation.component.html",
  styleUrls: ["./top-navigation.component.scss"],
})
export class TopNavigationComponent implements OnInit {
  town: City = new City();
  user: User = new User();
  applications: Array<Application> = [];

  subscription: Subscription;
  userSubscription: Subscription;

  constructor(
    private currentTownService: CurrentTownService,
    private currentUserResource: CurrentUserResource,
    private currentUserService: CurrentUserService,
    private cityApplicationsResource: CityApplicationsResource,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.currentUserResource.get().then(async (user) => {
      this.user = user;
      if (this.town.id) {
        this.applications = await this.getApplications();
      }
    });

    this.currentTownService.get().then(async (town) => {
      if (town) {
        this.town = town;
        if (this.user.id) {
          this.applications = await this.getApplications();
        }
      }
    });

    this.subscription = this.currentTownService
      .getChangeEmitter()
      .subscribe(async (town) => {
        if (town) {
          this.town = town;
          if (this.user.id) {
            this.applications = await this.getApplications();
          }
        }
      });

    this.userSubscription = this.currentUserService
      .getChangeEmitter()
      .subscribe(async (user) => {
        this.user = user;
        if (this.town.id) {
          this.applications = await this.getApplications();
        }
      });
  }

  logout() {
    sessionStorage.clear();
    this.currentUserResource.set(undefined);
    this.navigateToLogin();
  }

  navigateToDashboard() {
    this.router.navigate([this.town.slug]);
  }

  navigateToLogin() {
    this.router.navigate([this.town.slug, "login"]);
  }

  async getApplications() {
    const params = new StarkResourceParams({
      $header: {
        "x-city": this.town.slug,
      },
    });

    return await this.cityApplicationsResource.get(params);
  }

  hasApplication(application) {
    for (let i = 0; i < this.applications.length; i++) {
      if (application === this.applications[i].name) {
        return true;
      }
    }

    return false;
  }
}
