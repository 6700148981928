import {
  Component,
  forwardRef,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ComponentBase } from 'app/components/componentBase';

import { Field } from 'dataTypes';

import {
	HttpClientModule,
	HttpClient,
	HttpRequest,
	HttpResponse,
	HttpEventType,
} from '@angular/common/http';

import { environment } from '@environments/environment';

import { CurrentTownService, DocumentScanService } from 'services';
@Component({
  selector: 'field',
  templateUrl: './field.html',
  styleUrls: ['./field.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldComponent),
      multi: true,
    },
  ],
})
export class FieldComponent extends ComponentBase {
  @ViewChild('field') field;

  @Input() disabled: boolean;

  @Input() settings: Field;
  @Input() error: boolean;
  @Input() errorMessage: string;

  showCalendar: boolean = false;
  showLookupModal: boolean = false;

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  status: string;
  fileList: any[] = [];
	percentUploaded: number = 0;

  searchResults: any[] = [];
	searchString: string;
	showSearchResults: boolean = false;

	@ViewChild('file') fileElement: ElementRef;

  constructor(
    private _elementRef: ElementRef,
    private http: HttpClient,
		private currentTownService: CurrentTownService,
    private documentScanService: DocumentScanService,
  ) {
    super();
  }

  ngOnInit() {
		if (this.settings && this.settings.type === 'file') {
			const el = this._elementRef.nativeElement;

			el.addEventListener('dragover', this.handleDragOver.bind(this));

			el.addEventListener('dragleave', this.handleDragLeave.bind(this));

			el.addEventListener('drop', this.handleDrop.bind(this));
		}
	}

  ngDoCheck(prop) {
    this.setSelected();
  }

  ngOnDestroy() {
		const el = this._elementRef.nativeElement;

		el.removeEventListener('dragover', this.handleDragOver);

		el.removeEventListener('dragleave', this.handleDragLeave);

		el.removeEventListener('drop', this.handleDrop);
	}

	async handleDrop(e) {
		e.preventDefault();
		e.stopPropagation(); // Stops some browsers from redirecting.

		await this.handleSelectedFiles(e.dataTransfer.files);

		this.valueChanged(e);
	}

	handleDragOver(e) {
		e.preventDefault();
		e.stopPropagation(); // Stops some browsers from redirecting.

		this.status = 'hover';
	}

	handleDragLeave(e) {
		e.preventDefault();
		e.stopPropagation(); // Stops some browsers from redirecting.

		this.status = undefined;
	}

	async handleSelectedFiles(files) {
		this.status = undefined;
    if (this.settings.preUpload !== false) {
			for (let i = 0; i < files.length; i++) {
        files[i].infected = false;
				files[i].invalid = !this.allowedFileType(files[i]);
        // scan service to see if file is free of viruses, malware, etc
        // call returns boolean from response attribute CleanResult
        // only call scan api if file type is valid
        if (!files[i].invalid) {
          files[i].invalid = !this.documentScanService.scanDocument(files[i]);
          if (files[i].invalid) {
            files[i].infected = true;
          }
        }
				this.fileList.push({ file: files[i] });
			}

			if (!this.settings.value) {
				this.settings.value = [];
			}

			for (let i = 0; i < this.fileList.length; i++) {
				if (!this.fileList[i].url && !this.fileList[i].invalid) {
					const result: any = await this.uploadFile(i);

					this.settings.value.push(result.headers.get('location'));
				}
			}

			// this.fileList = [];
		} else {
			if (!this.settings.value) {
				this.settings.value = [];
			}
      for (let i = 0; i < files.length; i++) {
        files[i].infected = false;
				files[i].invalid = !this.allowedFileType(files[i]);
        // scan service to see if file is free of viruses, malware, etc
        // call returns boolean from response attribute CleanResult
        // only call scan api if file type is valid
        if (!files[i].invalid) {
          files[i].invalid = !this.documentScanService.scanDocument(files[i]);
          if (files[i].invalid) {
            files[i].infected = true;
          }
        }
				this.settings.value.push(files[i]);
			}
		}

		if (this.settings.maxCount) {
			this.settings.value.length = this.settings.maxCount;
		}
	}

	uploadFile(i) {
		return new Promise(async (resolve, reject) => {
			const file = this.fileList[i];

			const formData = new FormData();
			formData.append('file', file.file);

			file.percentUploaded = 0;
			const city = await this.currentTownService.get();

			let url = `${environment.candygramHostName}/files`;
			if (city) {
				url += `/${city.slug}`;
			}

			this.http
				.post(url, formData, { reportProgress: true, observe: 'events' })
				.subscribe((event) => {
					if (event.type == HttpEventType.UploadProgress) {
						file.percentUploaded = Math.round(
							(100 * event.loaded) / event.total
						);
					} else if (event instanceof HttpResponse) {
						setTimeout(() => {
							resolve(event);
						});
					} else if (event.constructor.name == 'HttpHeaderResponse') {
						setTimeout(() => {
							resolve(event);
						});
					}
				});
		});
	}

	removeFile(i) {
		this.settings.value.splice(i, 1);
	}

  evaluateOption(option, property) {
    return eval('option.' + property);
  }

  toggleCalendar = function () {
    this.showCalendar = !this.showCalendar;
  };

  calendarChanged(event) {
    if (event) {
      if (this.showCalendar == true) {
        this.ngModelChange.emit(event);
        this.toggleCalendar();
      }
    }
  }

  valueChanged(e) {
    if (this.settings.value != undefined) {
      if (this.settings.type == 'input') {
        if (this.settings.value.trim().length == 0) {
          this.settings.value = undefined;
        }
      }
    }

    this.ngModelChange.emit(e);
  }

  setSelected() {
    if (this.settings) {
      if (this.settings.type == 'select') {
        this.settings.options.forEach((option, index) => {
          if (JSON.stringify(this.settings.value) == JSON.stringify(option)) {
            this.settings.value = option;

            return;
          }
        });
      }
    }
  }

  toggleShowLookupModal() {
    this.showLookupModal = !this.showLookupModal;
  }

  selectLookupRow(e) {
    this.settings.value = e.row;
    this.ngModelChange.emit(e.row);

    this.showLookupModal = false;
  }

  incrament(number) {
    if (!isNaN(this.settings.value)) {
      this.settings.value += number;
    } else {
      this.settings.value = number;
    }

    this.ngModelChange.emit(number);
  }

  setFocus() {
    this.field.nativeElement.focus();
  }

  // selectItem(item) {
	// 	this.settings.value = item;

	// 	this.showSearchResults = false;
	// 	this.searchResults = [];
	// 	this.searchString = undefined;

	// 	this.valueChanged();
	// }

  browseFiles() {
  this.fileElement.nativeElement.click();
  }

  async test(e) {
    await this.handleSelectedFiles(e.target.files);
  }

  allowedFileType(file) {
  const allowedExtensions = [
    'csv',
    'doc',
    'docx',
    'dwg',
    'gif',

    'jpeg',
    'jpg',
  
    'png',
    'pdf',
    'ppt',
    'pptx',
    'tif',
    'tiff',

    'xls',
    'xlsx',

  ];

  const extension = file.name.split('.').pop();
  let valid = false;

  allowedExtensions.forEach((type) => {
    if (extension === type) {
      valid = true;
      return;
    }
  });
  return valid;
  }
}
