import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { CurrentTownService } from './currentTown.service';

@Injectable()
export class HammerApiService {
	
	private  requestQueue: Array<any> = [];

	public ServerWithApiUrl = environment.candygramHostName + '/';

	public processingRequest: boolean = false;

	constructor(
		private http: HttpClient,
		private currentTownService: CurrentTownService
	){
	}

	moveToNextRequest(){
		this.requestQueue.shift();

		this.loopRequests();
	}

	loopRequests(){

		if(this.requestQueue.length > 0){

			this.processingRequest = true;

			switch(this.requestQueue[0].type){
				case 'get':
						this.executeGet(this.requestQueue[0]);
					break;
				case 'put':
						this.executePut(this.requestQueue[0]);
					break;
				case 'post':
						this.executePost(this.requestQueue[0]);
					break;
				case 'delete':
						this.executeDelete(this.requestQueue[0]);
					break;
				default:
					this.executeGet(this.requestQueue[0]);
			}
		}else{
			this.processingRequest = false;
		}
	}

	get(url, data): Promise<any> {

		var queryParams = this.buildQueryString(data);

		var promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'get',
				resolve: resolve,
				reject: reject,
				data: data,
				url: url + queryParams
			})
		})

		if(this.processingRequest == false){

			this.loopRequests();
		}

		return promise;

	}

	executeGet(request){

		this.getHeader(request).then( options => {

    		this.http
					.get(this.ServerWithApiUrl+request.url, options)
					.toPromise()
					.then(this.extractData)
		      		.catch( (e) => {

		      			request.reject(e);

		      			this.moveToNextRequest();
		      		})
		      		.then( data => {

		      			request.resolve(data);

		      			this.moveToNextRequest();
		      		})
    	})
	}

	post(url, data): Promise<any>{

		var promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'post',
				resolve: resolve,
				reject: reject,
				data: data,
				url: url
			})
		})

		if(this.processingRequest == false){
			this.loopRequests();
		}

		return promise;

	}

	executePost(request){

		this.getHeader(request).then( options => {
			this.http
				.post(this.ServerWithApiUrl + request.url, request.data, options)
				.toPromise()
				.then( (res: any) => {

					request.resolve(this.extractData(res));

					this.moveToNextRequest();
				})
	      		.catch( (e) => {

	      			request.reject(e);

	      			this.moveToNextRequest();
	      		});
		});
	}


	put(url, data): Promise<any>{

		var promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'put',
				resolve: resolve,
				reject: reject,
				data: data,
				url: url
			})
		})

		if(this.processingRequest == false){
			this.loopRequests();
		}

		return promise;

	}

	executePut(request){

		this.getHeader(request).then( options => {
			this.http
				.put(this.ServerWithApiUrl + request.url, request.data, options)
				.toPromise()
				.then( (res: any) => {
					request.resolve(this.extractData(res));

					this.moveToNextRequest();
				})
	      		.catch( (e) => {

	      			request.reject(e);

	      			this.moveToNextRequest();
	      		});
		});
	}

	delete(url): Promise<any>{

		var promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'delete',
				resolve: resolve,
				reject: reject,
				url: url
			})
		})

		if(this.processingRequest == false){
			this.loopRequests();
		}

		return promise;

	}

	executeDelete(request){

		this.getHeader(request).then( options => {

    		this.http
				.delete(this.ServerWithApiUrl + request.url, options)
				.toPromise()
				.then( (res: any) => {
					request.resolve(this.extractData(res));

					this.moveToNextRequest();
				})
	      		.catch( (e) => {

	      			request.reject(e);

	      			this.moveToNextRequest();
	      		});
    	})
	}



	create(url, data): Promise<any>{

		return this.post(url, data);
	}

	update(url, data): Promise<any>{

		return this.put(url, data);
	}



	private extractData(res: any){

		let body = res;

		return body;
	}

	private handleError(error: any){

		return error;
		// return Observable.throw(error)
	}

	private getHeader(request){
		var promise = new Promise( (resolve, reject) => {
			var headers = new HttpHeaders();

			var user = JSON.parse( localStorage.getItem('user') );

			var accessToken = JSON.parse( localStorage.getItem('accessToken') );

			this.currentTownService.get().then( town => {
				if(accessToken){
					headers = headers.set('X-Token', accessToken.token);
				}

				if(town){
		    		headers = headers.set('X-City', town.slug);
		    	}

				var options = {
		    		headers: headers
		    	};

		    	resolve(options);
			})

			if (request.data.$header) {
				for ( const header in request.data.$header) {
					if (request.data.$header.hasOwnProperty(header)) {
						headers = headers.set(header, request.data.$header[header]);
					}
				}
			}

		})

    	return promise;
	}

	buildQueryString(params) {
        var queryParams = '?offset=' + params.offset + '&limit=' + params.limit;
        if (params.sort) {
            if (params.sort.length > 0) {
                var sortString = '';
                params.sort.forEach(value => {
                    if (sortString.length > 0) {
                        sortString += ",";
                    }
                    sortString += value.field + ':' + value.order;
                });
                queryParams += '&sortby=' + sortString;
            }
        }
        if (params.filters) {
            var filterString = '';
            params.filters.forEach(filter => {
                var fieldString = '';
                filter.forEach(field => {
                    if (fieldString.length > 0) {
                        fieldString += ',';
                    }
                    fieldString += field.property + ':' + field.operator + ':' + field.value;
                });
                if (fieldString.length > 0) {
                    fieldString = "(" + fieldString + ")";
                }
                if (filterString.length > 0) {
                    filterString += ',';
                }
                filterString += fieldString;
            });
            queryParams += "&filter=" + encodeURIComponent(filterString);
        }
        if (params.expand) {
            queryParams += "&expand=" + params.expand;
        }
        return queryParams;
    }
}