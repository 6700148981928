import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostListener
} from '@angular/core';

export enum KEY_CODE {
  ESCAPE = 27
}

@Component({
  selector: 'my-modal',
  templateUrl: './modal.html',
  styleUrls: ['./modal.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ModalComponent {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() print: EventEmitter<any> = new EventEmitter();

  @Input() show: boolean;
  @Input() printable: boolean;
  @Output() showChange: EventEmitter<any> = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  closeModal() {
    this.show = false;

    this.close.emit(false);
    this.showChange.emit(false);
  }

  @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {

    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.closeModal();
    }

  }

  printScreen() {
    this.print.emit();

    const head = document.getElementsByTagName('head')[0];
    const html = this.elementRef.nativeElement.querySelector('[body]').innerHTML;

    const mywindow = window.open('');
    mywindow.document.write(`
      <html>
        <head>
        ${head.innerHTML}
        </head>
        <body>
          ${html}
        </body>
      </html>
    `);

    mywindow.document.close();
    mywindow.focus();

    setTimeout(() => {
      mywindow.print();
      mywindow.close();
    });
  }
}
