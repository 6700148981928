import { Component, ViewEncapsulation, ElementRef } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { environment } from '../../../environments/environment';

import {
  ReceiptLineListResource,
  ReceiptsResource,
  ReceiptItemsResource,
  ReceiptTendersResource,
  BusinessesResource,
  ReceiptFeesResource,
  ResourceParams,
  Receipt,
  ReceiptItem,
  ReceiptTender,
  User,
  Business,
  License,
  ReceiptFee,
} from "@towncloud/thor-api";

import { CurrentUserResource } from "resources";

import { CurrentTownService } from "services";

import * as moment from "moment";

@Component({
  templateUrl: "./receipt.html",
  styleUrls: ["./receipt.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReceiptComponent {
  receipt: Receipt = new Receipt();
  lines: Array<ReceiptItem> = [];
  hasBusiness: boolean = false;

  paymentDate: string = "";
  confirmationNumber: any;
  subTotal: number = 0;
  feeTotal: number = 0;
  user: User = new User();
  businessLicense: License;
  fees: Array<ReceiptFee> = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private receiptLineListResource: ReceiptLineListResource,
    private receiptsResource: ReceiptsResource,
    private receiptItemsResource: ReceiptItemsResource,
    private receiptTendersResource: ReceiptTendersResource,
    private currentUserResource: CurrentUserResource,
    private receiptFeesResource: ReceiptFeesResource,
    private currentTownService: CurrentTownService,
  ) {}

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      this.getReceiptLines(params["licenseId"]).then(
        (receiptItems: Array<any>) => {
          if (receiptItems.length > 0) {
            this.getReceipt(receiptItems[0].receiptId).then(
              (receipt: Receipt) => {
                this.receipt = receipt;

                if (!this.receipt) {
                  return;
                }

                this.paymentDate = moment(this.receipt.dateCreated).format(
                  "MM/DD/YYYY"
                );

                this.getFees().then((fees: ReceiptFee[]) => {
                  this.fees = fees;

                  this.fees.forEach((fee) => {
                    this.feeTotal += parseFloat(String(fee.fee.amount));
                  });
                });

                this.getReceiptTenders(this.receipt.batchId).then(
                  (tenders: Array<ReceiptTender>) => {
                    tenders.forEach((t) => {
                      if (t.confirmationNumber)
                        this.confirmationNumber = t.confirmationNumber;
                    });
                  }
                );

                this.getReceiptItems().then((items: Array<any>) => {
                  this.lines = items;

                  this.subTotal = 0;

                  this.lines.forEach((line) => {
                    if (line.businessLicenseId) {
                      if (line.businessLicense.id) {
                        this.businessLicense = line.businessLicense;
                      }
                    }

                    this.subTotal += line.quantity * line.cost;
                  });
                });
              }
            );
          }
        }
      );
    });

    this.currentUserResource.get().then((user) => {
      this.user = user;
    });
  }

  getFees() {
    return new Promise((resolve) => {
      var params = new ResourceParams({
        $all: true,
        urlModifiers: {
          receiptId: this.receipt.id,
        },
        expand: ["fee"],
      });

      this.receiptFeesResource.get(params).then((fees) => {
        resolve(fees.items);
      });
    });
  }

  getReceiptTenders(batchId) {
    return new Promise((resolve, reject) => {
      var params = new ResourceParams({
        urlModifiers: {
          batchId: batchId,
        },
        expand: ["tender"],
        filters: [
          [
            {
              property: "receiptId",
              operator: "eq",
              value: this.receipt.id,
            },
          ],
        ],
      });

      this.receiptTendersResource.get(params).then((tenders) => {
        resolve(tenders.items);
      });
    });
  }

  getReceiptLines(licenseId) {
    return new Promise((resolve, reject) => {
      var data = new ResourceParams({
        filters: [
          [
            {
              property: "businessLicenseId",
              operator: "eq",
              value: licenseId,
            },
            {
              property: 'receipt.status',
              operator: 'eq',
              value: 'Active'
            }
          ],
        ],
        expand: ['receipt']
      });

      this.receiptLineListResource.get(data).then((receiptItems) => {
        resolve(receiptItems.items);
      });
    });
  }

  getReceipt(receiptId) {
    return new Promise((resolve, reject) => {
      var data = new ResourceParams({
        filters: [
          [
            {
              property: "id",
              operator: "eq",
              value: receiptId,
            },
          ],
        ],
      });

      this.receiptsResource.get(data).then((receipts) => {
        resolve(receipts.items[0]);
      });
    });
  }

  getReceiptItems() {
    return new Promise((resolve, reject) => {
      var data = new ResourceParams({
        $all: true,
        urlModifiers: {
          receiptId: this.receipt.id,
        },
        expand: ["chargeItem", "businessLicense","businessLicense.business"],
      });

      this.receiptItemsResource.get(data).then((lines) => {
        resolve(lines.items);
      });
    });
  }

  // printReceipt(): void {
  //   let printContents, popupWin, head;
  //   head = document.getElementsByTagName("head")[0].innerHTML;
  //   printContents = this.elementRef.nativeElement.querySelector(
  //     "#print-section"
  //   ).innerHTML;

  //   popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
  //   popupWin.document.open();

  //   popupWin.document.write(`
  //   <html>
  //     <head>
  //       <title>Print Receipt</title>
  //       ${head}
  //       <style>
  //       html, .print-section {width: 100%;}
  //       .btn-group-center {display:none; visibality: hidden;}
  //       .simple-card {width:100%; height: 100%}
  //       </style>
  //     </head>
  //     <body onload="window.print();window.close()">${printContents}</body>
  //   </html>`);
  //   popupWin.document.close();
  // }

  async printReceipt(){
    const town = await this.currentTownService.get();
    const token = JSON.parse(window.sessionStorage.getItem('accessToken')).token
    window.open(`${environment.candygramHostName}/reports/receipt/${this.receipt.id}?city=${town.slug}&token=${token}`)
  }

  viewCertificate(license) {
    this.router.navigate(["../../",license.id, "certificate"], { relativeTo: this.route });
  }
}
