import { Component, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

import {
  BusinessesResource,
  ResourceParams,
  User,
  Business,
} from "@towncloud/thor-api";

import { CurrentUserResource } from "resources";

@Component({
  templateUrl: "./businessInformation.html",
  styleUrls: ["./businessInformation.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BusinessInformationComponent {
  user: User = new User();
  business: Business = new Business();
  businessCopy: Business;

  showBusinessDetailModal: boolean = false;

  valid: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currentUserResource: CurrentUserResource,
    private businessesResource: BusinessesResource
  ) {}

  async ngOnInit() {
    let params = this.route.snapshot.params;

    this.user = await this.currentUserResource.get();

    this.business = await this.getBusiness(params["businessId"]);
  }

  async getBusiness(businessId) {
    const data = new ResourceParams({
      urlModifiers: {
        userId: this.user.id,
      },
      expand: [
        "ownershipType",
        "physicalAddress.state",
        "mailingAddress.state",
      ],
      filters: [
        [
          {
            property: "id",
            operator: "eq",
            value: businessId,
          },
        ],
      ],
    });

    return (await this.businessesResource.get(data)).items[0];
  }

  goToAddBusiness() {
    this.router.navigate(["add"], { relativeTo: this.route });
  }

  displayBusinessDetailModal() {
    this.businessCopy = JSON.parse(JSON.stringify(this.business));
    this.showBusinessDetailModal = true;
  }

  closeBusinessDetailModal() {
    this.showBusinessDetailModal = false;
    this.businessCopy = undefined;
  }

  save() {
    const params = {
      urlModifiers: {
        id: this.business.id,
      },
      name: this.businessCopy.name,
      doingBusinessAs: this.businessCopy.doingBusinessAs,
      ownershipTypeId: this.businessCopy.ownershipType
        ? this.businessCopy.ownershipType.id
        : undefined,
      stateRegistrationNumber: this.businessCopy.stateRegistrationNumber,
      taxIdetificationNumber: this.businessCopy.taxIdetificationNumber,
      phone: this.businessCopy.phone,
      fax: this.businessCopy.fax,
      website: this.businessCopy.website,
      physicalAddress: {
        address1: this.businessCopy.physicalAddress.address1,
        address2: this.businessCopy.physicalAddress.address2,
        city: this.businessCopy.physicalAddress.city,
        stateId: this.businessCopy.physicalAddress.stateId,
        stateName: this.businessCopy.physicalAddress.stateName,
        countryId: this.businessCopy.physicalAddress.countryId,
        zipCode: this.businessCopy.physicalAddress.zipCode,
      },
      mailingAddress: {
        address1: this.businessCopy.mailingAddress.address1,
        address2: this.businessCopy.mailingAddress.address2,
        city: this.businessCopy.mailingAddress.city,
        stateId: this.businessCopy.mailingAddress.stateId,
        stateName: this.businessCopy.mailingAddress.stateName,
        countryId: this.businessCopy.mailingAddress.countryId,
        zipCode: this.businessCopy.mailingAddress.zipCode,
      },
    };

    console.log(params, this.business);

    this.businessesResource.update(params).then(async (business) => {
      this.business = await this.getBusiness(this.business.id);

      this.showBusinessDetailModal = false;
    });
  }
}
