import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import {
  BusinessesResource,
  AttachmentsResource,
  BusinessAttachmentsResource,
  ResourceParams,
  User,
} from '@towncloud/thor-api';

import { CurrentUserService } from 'services/currentUser.service';

@Injectable()
export class DocumentsService {
  change = new EventEmitter();
  user: User = new User();
  userSubscription: Subscription;
  documents: any = {};

  constructor(
    private currentUserService: CurrentUserService,
    private businessesResource: BusinessesResource,
    private attachmentsResource: AttachmentsResource,
    private businessAttachmentsResource: BusinessAttachmentsResource,
    
  ) {
    if (sessionStorage.getItem('user')) {
      this.user = JSON.parse(sessionStorage.getItem('user'));
      this.updateDocuments();
    }

    this.userSubscription = this.currentUserService
      .getChangeEmitter()
      .subscribe((user) => {
        if (this.user.id !== user.id) {
          this.user = user;
          this.updateDocuments();
        }
      });
  }

  emitChangeEvent() {
    this.change.emit(this.documents);
  }

  getChangeEmitter() {
    return this.change;
  }

  get() {
    return this.documents;
  }

  updateDocuments() {
    setTimeout(() => {
      this.documents = {};
      if (this.user.id) {
        this.getBusinessDocuments().then((documents) => {
          this.documents = documents;
          this.emitChangeEvent();
        });
      }
    });
  }

  getBusinessDocuments() {
    return new Promise((resolve, reject) => {
      const data = new ResourceParams({
        urlModifiers: {
          userId: this.user.id,
        },
      });

      this.businessesResource.get(data).then((businesses) => {
        if (businesses && businesses.items.length > 0) {
          const params = new ResourceParams({
            urlModifiers: {
              userId:  this.user.id,
          },
            expand: ['documentType', 'businessAttachmentDetail'],
          });
          
          this.attachmentsResource.get(params).then((documents) => {
            resolve(documents.items);
          });
        }
      });
    });
  }

}
