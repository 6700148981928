import { ControlValueAccessor } from '@angular/forms';

export abstract class ComponentBase implements ControlValueAccessor {

  constructor() {}

  //The internal data model
  private innerValue: any = '';

  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  //get accessor
  get value(): any {
      return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {

      if (v !== this.innerValue) {
          this.innerValue = v;

          // if(typeof this.onChangeCallback == 'function'){
            this.onChangeCallback(v);
          // }
      }
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {

      if (value !== this.innerValue) {
          this.innerValue = value;
          // if(typeof this.onChangeCallback == 'function'){
            this.onChangeCallback(value);
          // }          
      }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
      this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
      this.onTouchedCallback = fn;
  }

  getValue = function(){
    return this.innerValue;
  }

}