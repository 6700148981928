import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import {
  ContactTypesResource,
  StatesResource,
  CountriesResource,

  ResourceParams,
  Contact,
  ContactType
} from '@towncloud/thor-api';

import { Field } from 'dataTypes';


import { FormService } from 'services';

@Component({
  selector: 'my-contact',
  templateUrl: './contactDetail.html',
  styleUrls: [ './contactDetail.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ContactDetailComponent {
  @Input() contact: Contact = new Contact();

  @Input() valid: boolean = false;
  @Output() validChange = new EventEmitter();

  @Output() contactChange: EventEmitter<any> = new EventEmitter();

  fields: any = {
    title: {},
    name: {},
    type: {},
    preferredName: {},
    homePhone: {},
    cellPhone: {},
    workPhone: {},
    fax: {},
    email: {}
  };
  address: any = {};
  _contact: Contact = new Contact();

  constructor(
    private contactTypesResource: ContactTypesResource,
    private statesResource: StatesResource,
    private countriesResource: CountriesResource,
    private formService: FormService
  ) {
    this.buildFields();
  }

  async ngOnInit(){
    this.fields.type.options = await this.getContactTypes();

    setTimeout( () => {
      this.setFormValidation();
    })
  }

  ngOnChanges() {
    if (this._contact.id !== this.contact.id) {

      this._contact = JSON.parse(JSON.stringify(this.contact));

      this.populateForm();
    }
  }

  buildFields() {
    this.fields.title = new Field({
      type: 'input',
      label: 'Title'
    });

    this.fields.name = new Field({
      type: 'input',
      label: 'Name',
      required: 'true',
      placeholder: 'Full name'
    });

    this.fields.type = new Field({
      type: 'select',
      label: 'Type',
      required: 'true',
      placeholder: 'Type',
      options: [],
      optionLabel: 'code'
    })

    this.fields.preferredName = new Field({
      type: 'input',
      label: 'Preferred Name',
      placeholder: 'What do you like to be called'
    });

    this.fields.homePhone = new Field({
      type: 'input',
      label: 'Home Phone',
      placeholder: '###-###-####'
    });

    this.fields.cellPhone = new Field({
      type: 'input',
      label: 'Cell Phone',
      placeholder: '###-###-####'
    });

    this.fields.workPhone = new Field({
      type: 'input',
      label: 'Work Phone',
      placeholder: '###-###-####'
    });

    this.fields.fax = new Field({
      type: 'input',
      label: 'Fax',
      placeholder: '###-###-####'
    });

    this.fields.email = new Field({
      type: 'input',
      label: 'Email',
      placeholder: 'example@email.com'
    });
  }

  async getContactTypes(){


      var params = new ResourceParams({
        $all: true,
        sort: [{
          field: 'code',
          order: 'ASC'
        }]
      })

      let types = await this.contactTypesResource.get(params);

      let results = [];
      types.items.forEach( (i) => {
        results.push( new ContactType(i));
      })
      return results;

  }

  populateForm() {

    this.fields.title.value = this._contact.title;
    this.fields.name.value = this._contact.name;
    this.fields.preferredName.value = this._contact.preferredName;
    this.fields.type.value = this._contact.type;

    this.fields.homePhone.value = this._contact.homePhone;
    this.fields.cellPhone.value = this._contact.cellPhone;
    this.fields.workPhone.value = this._contact.workPhone;
    this.fields.fax.value = this._contact.fax;
    this.fields.email.value = this._contact.email;

    this.address.address1 = this._contact.address.address1;
    this.address.address2 = this._contact.address.address2;
    this.address.city = this._contact.address.city;
    this.address.state = this._contact.address.state;
    this.address.stateName = this._contact.address.stateName;
    this.address.zipCode = this._contact.address.zipCode;
    this.address.country = this._contact.address.countryId;

    setTimeout( () => {
      this.setFormValidation();
    })

  }

  fieldChanged() {


    this._contact.title = this.fields.title.value;
    this._contact.name = this.fields.name.value;
    this._contact.preferredName = this.fields.preferredName.value;
    this._contact.type = this.fields.type.value;

    this._contact.homePhone = this.fields.homePhone.value;
    this._contact.cellPhone = this.fields.cellPhone.value;
    this._contact.workPhone = this.fields.workPhone.value;
    this._contact.fax = this.fields.fax.value;
    this._contact.email = this.fields.email.value;

    this._contact.address.address1 = this.address.address1;
    this._contact.address.address2 = this.address.address2;
    this._contact.address.city = this.address.city;
    this._contact.address.state = this.address.state;
    this._contact.address.stateId = this.address.state? this.address.state.id: undefined;
    this._contact.address.stateName = this.address.stateName;
    this._contact.address.zipCode = this.address.zipCode;
    this._contact.address.countryId = this.address.country;

    this.setFormValidation();

    this.contactChange.emit(this._contact);
  }

  setFormValidation(){
    this.valid = this.formService.isRequiredFieldsValid(this.fields);
    this.validChange.emit(this.valid);
  }

  save() {
    this.contact.title = this.fields.title.value;
    this.contact.name = this.fields.name.value;
    this.contact.preferredName = this.fields.preferredName.value;
    this.contact.type = this.fields.type.value;

    this.contact.homePhone = this.fields.homePhone.value;
    this.contact.cellPhone = this.fields.cellPhone.value;
    this.contact.workPhone = this.fields.workPhone.value;
    this.contact.fax = this.fields.fax.value;
    this.contact.email = this.fields.email.value;

    this.contact.address.address1 = this.address.address1;
    this.contact.address.address2 = this.address.address2;
    this.contact.address.city = this.address.city;
    this.contact.address.state = this.address.state;
    this.contact.address.stateName = this.address.stateName;
    this.contact.address.zipCode = this.address.zipCode;
    this.contact.address.country = this.address.country;
  }
}
