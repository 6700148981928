import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { City } from '@towncloud/thor-api';
import { ApiError } from 'dataTypes';

import { environment } from '@environments/environment';
import { CurrentTownService } from './currentTown.service';

@Injectable()
export class ApiService {

	private requestQueue: Array<any> = [];

	public ApiUrl: string = '/';
	// public ServerWithApiUrl = AppSettings.apiServer+ AppSettings.apiUrl;
	public ServerWithApiUrl = environment.thorHostName + '/';

	private actionUrl: string;

	town: City;
	processingRequest: boolean = false;

	constructor(
		private http: HttpClient,
		private currentTownService: CurrentTownService
	){
		this.actionUrl = this.ServerWithApiUrl + 'users';
	}

	private loopRequests(){
		if(this.requestQueue.length > 0){

			switch(this.requestQueue[0].type){
				case 'get':
						this.executeGet(this.requestQueue[0]);
					break;
				case 'put':
						this.executePut(this.requestQueue[0]);
					break;
				case 'post':
						this.executePost(this.requestQueue[0]);
					break;
				case 'delete':
						this.executeDelete(this.requestQueue[0]);
					break;
				default:
					this.executeGet(this.requestQueue[0]);
			}
		}
	}

	get(data): Promise<any> {

		var promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'get',
				resolve: resolve,
				reject: reject,
				data: data
			})
		})

		if(this.processingRequest == false){
			this.loopRequests();
		}

		return promise;
    	
	}

	private executeGet(request){

		this.processingRequest = true;

		this.getHeader().then( options => {

    		this.http
					.get(this.ServerWithApiUrl+request.data, options)
					.toPromise()
					.then(this.extractData)
		      		.catch((e) => {
		      			request.reject(this.handleError(e));
		      		})
		      		.then( data => {
		      			request.resolve(data);

		      			this.requestQueue.shift();

		      			this.processingRequest = false;

		      			this.loopRequests();
		      		})
    	})
	}

	post(url, data): Promise<any>{

		var promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'post',
				resolve: resolve,
				reject: reject,
				data: data,
				url: url
			})
		})

		if(this.processingRequest == false){
			this.loopRequests();
		}

		return promise;


		// return this.getHeader().then( options => {
		// 	return this.http
		// 		.post(this.ServerWithApiUrl + url, data, options)
		// 		.toPromise()
		// 		.then(this.extractData)
	 //      		.catch(this.handleError);
		// });
	}

	private executePost(request){

		this.getHeader().then( options => {
			this.http
				.post(this.ServerWithApiUrl + request.url, request.data, options)
				.toPromise()
				.then(this.extractData)
	      		.catch((e) => {
	      			request.reject(this.handleError(e));
	      		})
	      		.then( data => {

	      			request.resolve(data);

	      			this.requestQueue.shift();

	      			this.processingRequest = false;

	      			this.loopRequests();
	      		});
		});
	}


	put(url, data): Promise<any>{

		var promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'put',
				resolve: resolve,
				reject: reject,
				data: data,
				url: url
			})
		})

		if(this.processingRequest == false){
			this.loopRequests();
		}

		return promise;

	}

	private executePut(request){

		this.getHeader().then( options => {
			this.http
				.put(this.ServerWithApiUrl + request.url, request.data, options)
				.toPromise()
				.then(this.extractData)
	      		.catch((e) => {
	      			request.reject(this.handleError(e));
	      		})
	      		.then( data => {

	      			request.resolve(data);

	      			this.requestQueue.shift();

	      			this.processingRequest = false;

	      			this.loopRequests();
	      		});
		});
	}

	patch(url, data): Promise<any>{

		var promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'patch',
				resolve: resolve,
				reject: reject,
				data: data,
				url: url
			})
		})

		if(this.processingRequest == false){
			this.loopRequests();
		}

		return promise;
	}

	private executePatch(request){

		this.getHeader().then( options => {
			this.http
				.patch(this.ServerWithApiUrl + request.url, request.data, options)
				.toPromise()
				.then(this.extractData)
	      		.catch((e) => {
	      			request.reject(this.handleError(e));
	      		})
	      		.then( data => {

	      			request.resolve(data);

	      			this.requestQueue.shift();

	      			this.processingRequest = false;

	      			this.loopRequests();
	      		});
		});
	}

	delete(url): Promise<any>{

		var promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'delete',
				resolve: resolve,
				reject: reject,
				url: url
			})
		})

		if(this.processingRequest == false){
			this.loopRequests();
		}

		return promise;

	}

	private executeDelete(request){

		this.processingRequest = true;

		this.getHeader().then( options => {

    		this.http
				.delete(this.ServerWithApiUrl + request.url, options)
				.toPromise()
				.then(this.extractData)
	      		.catch((e) => {
	      			request.reject(this.handleError(e));
	      		})
	      		.then( data => {
	      			request.resolve(data);

	      			this.requestQueue.shift();

	      			this.processingRequest = false;

	      			this.loopRequests();
	      		})
    	})
	}

	

	create(url, data): Promise<any>{

		return this.post(url, data);
	}

	update(url, data): Promise<any>{

		return this.put(url, data);
	}



	private extractData(res){

		let body = res;

		return body;
	}

	private handleError(error: any){
		return new ApiError(error);
	}

	private getHeader(){
		var promise = new Promise( (resolve, reject) => {
			var headers = new HttpHeaders();

			var user = JSON.parse( sessionStorage.getItem('user') );
			
			var accessToken = JSON.parse( sessionStorage.getItem('accessToken') );

			this.currentTownService.get().then( town => {

				if(accessToken){
					if(accessToken.token){
						headers = headers.set('X-Token', accessToken.token);
					}					
				}				

				if(town){
		    		headers = headers.set('X-City', town.slug);
		    	}


		    	var options = {
		    		headers: headers
		    	};

		    	resolve(options);
			})
	    	
		})	

    	return promise
	}
}