import { Component } from "@angular/core";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";

import { Application, City, ResourceParams } from "@towncloud/thor-api";

import { CityApplicationsResource } from "resources";

import { ResourceParams as StarkResourceParams } from "dataTypes";

import { CurrentTownService } from "services";
@Component({
  templateUrl: "./dashboard.html",
})
export class DashboardComponent {
  applications: Application[] = [];
  town: City;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cityApplicationsResource: CityApplicationsResource,
    private currentTownService: CurrentTownService
  ) {}

  async ngOnInit() {
    this.town = await this.currentTownService.get();

    if (this.town) {
      this.applications = await this.getCityApplications();
      if (!this.hasApplication("Procurement")) {
        this.navigateToBusinesses();
      }
    }
  }

  async getCityApplications() {
    const params = new StarkResourceParams({
      urlModifiers: {
        cityId: this.town.id,
      },
    });

    return await this.cityApplicationsResource.get(params);
  }

  hasApplication(application) {
    for (let i = 0; i < this.applications.length; i++) {
      if (application === this.applications[i].name) {
        return true;
      }
    }

    return false;
  }

  navigateToBusinesses() {
    const navigationExtras: NavigationExtras = {
      relativeTo: this.route,
    };
    this.router.navigate(["mybusiness"], navigationExtras);
  }
}
