import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './addBusiness.html',
  styleUrls: [ './addBusiness.scss' ],
  encapsulation: ViewEncapsulation.None
})

export class AddBusinessComponent {
	
	constructor(
		private router: Router,
		private route: ActivatedRoute,
	) {}

	goToPin(){
		this.router.navigate(["pin"], { relativeTo: this.route });
	}

	goToCreate(){
		this.router.navigate(["create"], { relativeTo: this.route });
	}
}