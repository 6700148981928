import { Injectable } from '@angular/core';
import { DataService, CurrentUserService } from 'services';

import {
  UserPermissionsResource,
  UsersResource,

  ResourceParams,
  User
} from '@towncloud/thor-api';

export let CURRENT_USER: User = new User();

@Injectable()
export class CurrentUserResource {
  permissions: any;

  constructor(
    private dataService: DataService,
    private currentUserService: CurrentUserService,
    private usersResource: UsersResource,
    private userPermissionsResource: UserPermissionsResource
  ) {
    if (sessionStorage.getItem('user')) {
      CURRENT_USER = JSON.parse(sessionStorage.getItem('user'));
    }
  }

  get(): Promise<any> {
    if (this.dataService.isOffline()) {
      return new Promise((resolve, reject) => {
        resolve(CURRENT_USER);
      });
    } else {
      // do something to get current user from API
      if (CURRENT_USER.id) {
        return new Promise((resolve, reject) => {
          resolve(CURRENT_USER);
        });
        // return this.usersResource.select(CURRENT_USER.id)
      } else {
        return new Promise((resolve, reject) => {
          resolve({});
        });
      }
    }
  }

  set(user: User) {
    if (user) {
      sessionStorage.setItem('user', JSON.stringify(user));
      CURRENT_USER = user;
    } else {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('accessToken');
      CURRENT_USER = new User();
    }

    this.currentUserService.emitChangeEvent(CURRENT_USER);
  }

  getPermissions() {
    return new Promise((resolve, reject) => {
      this.get().then(user => {
        if (this.permissions) {
          resolve(this.permissions);
        }
        if (user.id) {
          const data = new ResourceParams({
            $all: true,
            urlModifiers: {
              userId: user.id
            }
          });

          this.userPermissionsResource.get(data).then(permissions => {
            this.permissions = permissions;
            resolve(permissions);
          });
        } else {
          resolve({ items: [] });
        }
      });
    });
  }
}
