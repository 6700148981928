import { Pipe, PipeTransform } from "@angular/core";
import { CreditCard } from "../classes/creditCard";

@Pipe({
	name: "fieldMask",
})
export class FieldMask implements PipeTransform {
	transform(value, mask) {
		let maskedValue = value;

		switch (mask) {
			case "creditCard":
				const cc = new CreditCard(value);
				maskedValue = cc.format();
				break;
		}

		return maskedValue;
	}
}
