import { Component, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';

import {
  User,
  ResourceParams,
  City,
  ReceiptItem,
  ApiService,
  UserCitiesResource,
  ItemsResource,
} from '@towncloud/thor-api';

import {
  CurrentUserService,
  CurrentTownService,
  CartService,
  NoticesService,
} from 'services';

import { CurrentUserResource } from 'resources';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title = 'app';

  user: User = new User();
  town: City = new City();
  userSubscription: Subscription;
  townSubscription: Subscription;
  noticeSubscription: Subscription;
  cart: Array<any> = [];
  notices: any = {};
  showNotices: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currentUserResource: CurrentUserResource,
    private currentUserService: CurrentUserService,
    private currentTownService: CurrentTownService,
    private userCitiesResource: UserCitiesResource,

    private noticesService: NoticesService,
    private cartService: CartService,
    private itemsResource: ItemsResource
  ) {}

  ngOnInit() {
    if (sessionStorage.getItem('accessToken')) {
      const accessToken = JSON.parse(sessionStorage.getItem('accessToken'));
      ApiService.setToken(accessToken.token);
    }

    this.cart = this.cartService.get();

    this.dislayNotices();
    this.noticeSubscription = this.noticesService
      .getChangeEmitter()
      .subscribe((notices) => {
        this.notices = notices;

        this.dislayNotices();
      });

    setTimeout(() => {
      this.currentUserResource.get().then((user: User) => {
        this.user = user;
        this.setNotices();

        this.currentTownService.get().then((town) => {
          this.town = town;

          if (!this.user.id) {
            // this.navigateToLogin();
          }

          this.subscribeToTownService();
        });
      });
    });

    this.userSubscription = this.currentUserService
      .getChangeEmitter()
      .subscribe((user) => {
        this.setNotices();
        if (this.user.id !== user.id) {
          this.user = user;

          this.setUserTown().then((town) => {
            this.navigateToRoot();
          });
        } else {
          this.user = user;
        }
      });

    this.cartService.change().subscribe((cart) => {
      this.cart = cart;

      this.dislayNotices();
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.townSubscription) {
      this.townSubscription.unsubscribe();
    }

    if (this.noticeSubscription) {
      this.noticeSubscription.unsubscribe();
    }
  }

  subscribeToTownService() {
    this.townSubscription = this.currentTownService
      .getChangeEmitter()
      .subscribe((town) => {
        this.town = town;
      });
  }

  setUserTown() {
    return new Promise((resolve, reject) => {
      if (this.user.id && this.town.slug === undefined) {
        const params = new ResourceParams({
          urlModifiers: {
            userId: this.user.id,
          },
          sort: [
            {
              field: 'id',
              order: 'ASC',
            },
          ],
        });

        this.userCitiesResource.get(params).then((cities) => {
          this.town = cities.items[0];

          resolve(this.town);
        });
      } else {
        resolve(this.town);
      }
    });
  }

  setNotices() {
    this.notices = this.noticesService.get();
  }

  navigateToRoot() {
    if (this.user.id) {
      this.router.navigate([this.town.slug]);
    } else {
      // this.navigateToLogin();
    }
  }

  navigateToCityRoot() {
    this.router.navigate([this.town.slug]);
  }

  navigateToLogin() {
    if (this.town && this.town.slug) {
      if (!this.route.snapshot.queryParams.token) {
        this.router.navigate([this.town.slug, 'login']);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  navigateToBusinessDocuments(businessId) {
    this.currentTownService.get().then((town) => {
      this.router.navigate([town.slug, 'mybusiness', businessId, 'documents']);
    });
  }

  dislayNotices() {
    this.showNotices = false;

    if (this.notices.pendingLicenses) {
      if (this.notices.pendingLicenses.length > 0) {
        this.notices.pendingLicenses.forEach((license) => {
          if (!this.itemIsInCart(license)) {
            this.showNotices = true;
          }
        });
      }
    }
  }

  itemIsInCart(item) {
    let inCart = false;

    this.cart.forEach((c) => {
      if (c.businessLicense.id === item.id) {
        inCart = true;

        return;
      }
    });

    return inCart;
  }

  addToCart(license) {
    const params = new ResourceParams({
      expand: ['application'],
      filters: [
        [
          {
            property: 'application.name',
            operator: 'eq',
            value: 'Licenses',
          },
        ],
      ],
    });

    this.itemsResource.get(params).then((items) => {
      const item = items.items[0];
      const licenseFeeTotal = license.exemptFromFee ? 0 : license.feeTotal;

      const receiptItem = new ReceiptItem({
        itemId: item.id,
        item: item,
        chargeItem: item,
        quantity: 1,
        cost: licenseFeeTotal,
        businessLicenseId: license.id,
        businessLicense: license,
      });

      this.cartService.add(receiptItem);

      this.cart = this.cartService.get();
    });
  }
}
