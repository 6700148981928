import { Component, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  templateUrl: './noTown.html',
  encapsulation: ViewEncapsulation.None,
})

export class NoTownComponent {
	
	constructor() {}

}