import { Component, Input, Output, EventEmitter } from '@angular/core';

import {
  CountriesResource,
  StatesResource,

  ResourceParams,
  Country,
  State
} from '@towncloud/thor-api';

import { Field } from 'dataTypes';

@Component({
  selector: 'tc-address',
  templateUrl: './address.html'
})
export class AddressComponent {
  private _settings: any = {};
  @Input() settings: any = {};

  @Input() type: string = 'local';

  @Input() required: boolean;

  @Input() disabled: boolean;

  @Input() isValid: boolean = true;
  @Output() isValidChange: EventEmitter<any> = new EventEmitter();

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  address1: Field;
  address2: Field;
  city: Field;
  state: Field;
  stateName: Field;
  zipCode: Field;
  country: Field;

  physicalAddress;
  hasStates: boolean = true;

  gettingCountries;
  gettingsStates;

  lastCountryApplied;

  constructor(
    private countriesResource: CountriesResource,
    private statesResource: StatesResource
  ) {
    this.country = new Field({
      type: 'select',
      label: 'Country',
      options: [],
      optionLabel: 'name',
      required: false,
      placeholder: 'Select'
    });

    this.address1 = new Field({
      type: 'input',
      label: 'Address',
      placeholder: 'Street address'
    });

    this.address2 = new Field({
      type: 'input',
      placeholder: 'Street address'
    });

    this.city = new Field({
      type: 'input',
      label: 'City',
      placeholder: 'City name'
    });

    this.state = new Field({
      type: 'select',
      label: 'State',
      options: [],
      optionLabel: 'name',
      required: false,
      placeholder: 'Select'
    });

    this.stateName = new Field({
      type: 'input',
      label: 'State Name',
      placeholder: 'Select'
    });

    this.zipCode = new Field({
      type: 'input',
      label: 'Zip Code',
      placeholder: 'Zip code'
    });

    setTimeout(() => {
      this.setIsValid();
    });
  }

  ngOnChanges(changes) {

    this.applySettings();

    this.country.disabled = this.disabled;
    this.address1.disabled = this.disabled;
    this.address2.disabled = this.disabled;
    this.city.disabled = this.disabled;
    this.state.disabled = this.disabled;
    this.stateName.disabled = this.disabled;
    this.country.disabled = this.disabled;
    this.zipCode.disabled = this.disabled;
  }

  ngDoCheck() {
    this.applySettings();

    this.isValidChange.emit(this.isValid);
  }

  ngOnInit() {
    this.getCountries().then((countries: Array<Country>) => {
      this.country.options = countries;
    });

    this.country.required = this.required;
    this.address1.required = this.required;
    this.city.required = this.required;
    this.state.required = this.required;
    this.stateName.required = this.required;
    this.country.required = this.required;
    this.zipCode.required = this.required;
  }

  applySettings() {
    this._settings.address1 = this.settings.address1;
    this._settings.address2 = this.settings.address2;
    this._settings.city = this.settings.city;
    this._settings.stateName = this.settings.stateName;
    this._settings.zipCode = this.settings.zipCode;
    this._settings.state = this.settings.state;
    this._settings.country = this.settings.country;

    if (this.country.options.length > 0) {
      if (this.settings.country) {
        if ( (!this.country.value && this._settings.country) || this._settings.country.id !== this.country.value.id) {
          this.country.options.forEach((country: Country) => {
            if (country.id == this.settings.country) {
              this.country.value = country;
              this._settings.country = this.settings.country;

              return;
            }
          });

          this.applyCountrySettings();
        }
      } else {
        this.country.options.forEach((country: Country) => {
          if (country.name == 'United States of America') {
            this.country.value = country;
            this.settings.country = country;
            this._settings.country = country;

            setTimeout(() => {
              this.setIsValid();
            });

            return;
          }
        });

        this.applyCountrySettings();
      }
    }

    this.address1.value = this.settings.address1;
    this.address2.value = this.settings.address2;
    this.city.value = this.settings.city;
    this.stateName.value = this.settings.stateName;

    if (this.state.options.length > 0) {
      if (this._settings.state) {
        if ( (!this.state.value && this._settings.state) || this._settings.state.id !== this.state.value.id) {
          this.state.options.forEach((state: State) => {

            if (state.id == this._settings.state.id) {

              this.state.value = state;

              return;
            }
          });

        }
      } else {
        this.state.value = undefined;
      }
    }

    this.zipCode.value = this.settings.zipCode;
  }

  getCountries() {
    return new Promise((resolve, reject) => {
      var params = new ResourceParams({
        $all: true,
        sort: [
          {
            field: 'name',
            order: 'ASC'
          }
        ]
      });

      this.countriesResource.get(params).then(countries => {
        resolve(countries.items);
      });
    });
  }

  fieldChanged(field) {
    this.settings[field] = this[field].value;

    if (field === 'country') {
      // this.settings[field] = this[field].value;

      this.settings.state = undefined;
      this.settings.stateName = undefined;

      this.applyCountrySettings();
    // } else if (field === 'state') {
    //   this.settings[field] = this[field].value;
    } else {
      // this.settings[field] = this[field].value;
    }

    this.setIsValid();

    this.ngModelChange.emit(field);
  }

  setIsValid() {
    this.isValid = true;
    if (this.required) {
      if (
        !this.settings.address1 ||
        !this.settings.city ||
        !this.settings.zipCode ||
        (!this.settings.country && this.type === 'international')
      ) {
        this.isValid = false;
      } else if (!this.settings.state && !this.settings.stateName) {
        this.isValid = false;
      }
    }

    this.isValidChange.emit(this.isValid);
  }

  applyCountrySettings() {

    let country;

    if(this.settings.country.constructor.name == 'Object'){
      country = this.settings.country.id
    }else{
      country = this.settings.country
    }

    if(this.lastCountryApplied == country){
      return false;
    }

    this.lastCountryApplied = country

    var params = new ResourceParams({
      $all: true,
      filters: [
        [
          {
            property: 'countryId',
            operator: 'eq',
            value: country
          }
        ]
      ],
      sort: [
        {
          field: 'name',
          order: 'ASC'
        }
      ]
    });

    this.statesResource.get(params).then(states => {
      this.state.options = states.items;

      if (states.page.totalCount > 0) {
        this.state.required = this.required;
        this.stateName.required = false;

        this.hasStates = true;
      } else {
        this.state.required = false;
        this.stateName.required = this.required;

        this.hasStates = false;
      }
    });
  }
}
