import { Injectable, EventEmitter, } from '@angular/core';
import { Observable ,  Subject , BehaviorSubject} from 'rxjs';

export var MENU = [
	// {
	// 	item: 'Routes',
	// 	list: [
	// 		{
	// 			item: 'Route 1',
	// 			path: '/route1'
	// 		},
	// 		{
	// 			item: 'Route 2',
	// 			path: '/route2'
	// 		},
	// 		{
	// 			item: 'Routes 3',
	// 			path: '/route3'
	// 		}
	// 	]
	// }
];
export var MASTERMENU = [];
export var MAINMENU = [];

@Injectable()
export class MenuService {

	private _mainMenu = new BehaviorSubject<any>(MAINMENU);
	mainMenu = this._mainMenu.asObservable();

	// private _menu = new BehaviorSubject<any>(MENU);
	// public menu: Observable<any> = this._menu.asObservable();

	// private _masterMenu = new BehaviorSubject<any>(MAINMENU);
	// public masterMenu: Observable<any> = this._masterMenu.asObservable();

	constructor(){
	}

	push(item){
		MENU.push(item);

		// this._menu.next(MENU);
		this.setMain();
	}

	set(menu){
		MENU = menu;

		// this._menu.next(MENU);
		this.setMain();
	}

	pushToMaster(item){
		MASTERMENU.push(item);

		// this._masterMenu.next(MASTERMENU);
		this.setMain();
	}

	setMaster(menu){
		MASTERMENU = menu;

		// this._masterMenu.next(MASTERMENU);
		this.setMain();
	}

	setMain(){
		MAINMENU =  MASTERMENU.concat(MENU);

		this._mainMenu.next(MAINMENU);
	}

	get(){
		return MENU;
	}

	getMaster(){
		return MASTERMENU;
	}

	getMain(){
		return MAINMENU;
	}
}