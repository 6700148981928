import { DocumentsService } from 'services/documents.service';
import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import {
  BusinessesResource,
  BusinessLicenseListResource,
  BusinessLicensesResource,
  AttachmentsResource,
  BusinessAttachmentsResource,
  LicenseTypeDocumentTypesResource,
  ResourceParams,
  User,
} from '@towncloud/thor-api';

import { CurrentUserService } from 'services/currentUser.service';

@Injectable()
export class NoticesService {
  change = new EventEmitter();
  user: User = new User();
  userSubscription: Subscription;
  documentSubscription: Subscription;
  // notices: any = {};
  notices: any = [];
  documents: any = [];

  constructor(
    // private currentUserResource: CurrentUserResource,
    private currentUserService: CurrentUserService,
    private businessesResource: BusinessesResource,
    private businessLicenseListResource: BusinessLicenseListResource,
    private businessLicensesResource: BusinessLicensesResource,
    private attachmentsResource: AttachmentsResource,
    private businessAttachmentsResource: BusinessAttachmentsResource,
    private licenseTypeDocumentTypesResource: LicenseTypeDocumentTypesResource,
    private documentsService: DocumentsService,
  ) {
    if (sessionStorage.getItem('user')) {
      this.user = JSON.parse(sessionStorage.getItem('user'));
      this.documentsService.get();
      this.updateNotices();
    }

    this.documentSubscription = this.documentsService
    .getChangeEmitter()
    .subscribe((documents) => {
      this.documents = documents;
      this.updateNotices();

    });

    this.userSubscription = this.currentUserService
      .getChangeEmitter()
      .subscribe((user) => {
        if (this.user.id !== user.id) {
          this.user = user;
          this.documentsService.get();
          this.updateNotices();
        }
      });

  }

  emitChangeEvent() {
    this.change.emit(this.notices);
  }

  getChangeEmitter() {
    return this.change;
  }

  get() {
    if (this.notices.length === 0) {
      this.updateNotices();
    }
    return this.notices;
  }

  updateNotices() {
    setTimeout(() => {
      this.notices = {};
      if (this.user.id) {
        this.getPendingLicenses().then((licenses) => {
          this.notices.pendingLicenses = licenses;
        }).then(() => {
          this.checkForRequiredDocuments();
        }).then(() => {
          this.emitChangeEvent();
        }).catch (e => ( console.error(e)));
      }
    });
  }

  getPendingLicenses() {
    return new Promise((resolve, reject) => {
      const data = new ResourceParams({
        urlModifiers: {
          userId: this.user.id,
        },
        expand: [
          'ownershipType',
          'physicalAddress.state',
          'mailingAddress.state',
        ],
      });

      this.businessesResource.get(data).then((businesses) => {
        if (businesses && businesses.items.length > 0) {
          const params = new ResourceParams({
            urlModifiers: {
              userId: this.user.id
            },
            expand: ['type', 'business'],
            filters: [
              [
                {
                  property: 'status',
                  operator: 'eq',
                  value: 'Pending',
                },
              ],
            ],
            sort: [
              {
                field: 'year',
                order: 'DESC',
              },
            ],
          });

          this.businessLicensesResource.get(params).then((licenses) => {
            resolve(licenses.items);
          });
        }
      });
    });
  }

  checkForRequiredDocuments() {
    return new Promise((resolve, reject) => {
      this.notices.pendingLicenses.forEach((pendingLicense) => {

        let businessDocuments: any = [];
        let licenseDocumentTypes: any = [];
        pendingLicense.requiredDocumentTypes = [];

          businessDocuments = this.documents.filter(function(e) {
            return e.businessAttachmentDetail.businessId === pendingLicense.business.id;
          });

          this.getLicenseTypeDocuments(pendingLicense.type.id).then((documentTypes) => {
            licenseDocumentTypes = documentTypes;
            if (licenseDocumentTypes && licenseDocumentTypes.length > 0) {
              licenseDocumentTypes.forEach((requiredDocumentType) => {
                let requirementMet = false;
                if (businessDocuments && businessDocuments.length > 0) {
                  businessDocuments.forEach((businessDocument) => {
                    if (businessDocument.documentType.id === requiredDocumentType.documentType.id) {
                      requirementMet = true;
                      return;
                    }
                  });
                }
                if (!requirementMet) {
                  pendingLicense.requiredDocumentTypes.push(requiredDocumentType);
                }
              });
            }
          });
        // });
      });
    });
  }

  getLicenseTypeDocuments(licenseTypeId) {
    return new Promise((resolve, reject) => {
      let params = new ResourceParams({
        urlModifiers: {
          licenseTypeId: licenseTypeId
        },
        expand: ['documentType'],
      });

      this.licenseTypeDocumentTypesResource.get(params).then((documentTypes) => {
        resolve(documentTypes.items);
      });
    });
  }
}
