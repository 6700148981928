import { DataType } from "./dataType";

export class ResourceParamsSort extends DataType {
  field: string;
  order: string;

  constructor(object: Object = {}) {
    var properties = ["field", "order"];
    super(object, properties);
  }
}

export class ResourceParams extends DataType {
  offset: number;
  limit: number;
  // sort: ResourceParamsSort;
  sort: Array<ResourceParamsSort>;
  urlModifiers: any;
  filters: Array<Object>;
  expand: Array<string>;

  $bold: boolean;
  $all: true;
  $header: Object;

  constructor(object: Object = {}) {
    super(object, [
      "offset",
      "limit",
      "sort",
      "urlModifiers",
      "filters",
      "expand",
      "$bold",
      "$all",
      "$header",
    ]);

    this.sort = [];
    if (object["sort"]) {
      object["sort"].forEach((value) => {
        this.sort.push(new ResourceParamsSort(value));
      });
    }

    if (this.offset == undefined) {
      this.offset = 0;
    }
    if (this.limit == undefined) {
      this.limit = 10;
    }
  }
}
