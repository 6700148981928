import { Component, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

import { BusinessesResource, ResourceParams, User } from "@towncloud/thor-api";

import { CurrentUserResource } from "resources";

@Component({
  templateUrl: "./businessManagement.html",
  styleUrls: ["./businessManagement.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BusinessManagementComponent {
  user: User = new User();
  businesses: any[] = [];
  hasBusiness: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currentUserResource: CurrentUserResource,
    private businessesResource: BusinessesResource
  ) {}

  ngOnInit() {
    this.currentUserResource.get().then(async (user) => {
      this.user = user;

      await this.getBusiness();
    });
  }

  async getBusiness() {
    const data = new ResourceParams({
      urlModifiers: {
        userId: this.user.id,
      },
      expand: ["physicalAddress.state", "mailingAddress.state"],
    });

    this.businesses = (await this.businessesResource.get(data)).items;

    if (this.businesses.length > 0) {
      this.hasBusiness = true;
    }
  }

  goToAddBusiness() {
    this.router.navigate(["add"], { relativeTo: this.route });
  }

  goToBusinessDetail(id) {
    this.router.navigate([id], { relativeTo: this.route });
  }
}
