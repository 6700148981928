import { DataType } from 'dataType';

export class Field extends DataType {
  type: string;
  id: string;
  label: string;
  value: any;
  placeholder: string;
  subText: string;
  disabled: boolean;
  required: boolean;
  showOptions: boolean;
  min: number;
  max: number;
  mask: string;

  options: Array<Object>;
  optionLabel: string;

  lookupLabel: string;

  maxCount: number;
  maxFileSize: number;
	displayType: string;
	fileTypes: string[];
	preUpload: boolean;

  filelds: Array<Field>;

  constructor(object: Object = undefined) {
    const properties = [
      'type',
      'id',
      'label',
      'options',
      'optionLabel',
      'value',
      'placeholder',
      'subText',
      'disabled',
      'required',
      'showOptions',
      'lookupLabel',
      'min',
      'max',
      'mask',
      'maxCount',
      'maxFileSize',
			'displayType',
			'fileTypes',
			'preUpload',
    ];

    super(object, properties);
  }
}
