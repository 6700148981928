import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'procurement-template',
  templateUrl: './procurementTemplateComponent.html',
  encapsulation: ViewEncapsulation.None
})
export class ProcurementTemplateComponent {

  constructor(
  ) {  }
}
