import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { CurrentUserResource } from 'resources';

import { User } from '@towncloud/thor-api';

@Component({
  templateUrl: './myBusiness.html',
  styleUrls: ['./myBusiness.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyBusinessComponent {

  user: User = new User();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currentUserResource: CurrentUserResource
  ) {}

  ngOnInit() {
    this.currentUserResource.get().then(user => {
      this.user = user;
    });
  }

  showManageYourBuisness(){
    return !!(this.route.children[0].snapshot.params['businessId']);
  }
}
