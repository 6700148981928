import { Component, ViewEncapsulation } from "@angular/core";
import { Subscription } from "rxjs";

import { CartService } from "services";

import {
  BusinessesResource,
  ItemsResource,
  TendersResource,
  TenderFeesResource,
  ResourceParams,
  User,
  Business,
  Fee,
  Tender,
} from "@towncloud/thor-api";

import { CurrentUserResource } from "resources";

@Component({
  templateUrl: "./cart.html",
  styleUrls: ["./cart.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CartComponent {
  user: User = new User();
  business: Business = new Business();
  cart: Array<any> = [];
  fees: Array<Fee> = [];
  cartSub: Subscription;

  cartTotal: number = 0;
  cartSubTotal: number = 0;

  tender: Tender = new Tender();

  constructor(
    private currentUserResource: CurrentUserResource,
    private businessesResource: BusinessesResource,
    private cartService: CartService,
    private tendersResource: TendersResource,
    private tenderFeesResource: TenderFeesResource
  ) {}

  ngOnInit() {
    this.currentUserResource.get().then((user) => {
      this.user = user;

      this.getBusiness().then((business: Business) => {
        this.business = business;
      });
    });

    this.cart = this.cartService.get();
    this.setCartTotal();

    this.cartSub = this.cartService.change().subscribe((cart) => {
      this.cart = cart;

      this.setCartTotal();
    });

    let params = new ResourceParams({
      filters: [
        [
          {
            property: "isMerchantCredit",
            operator: "eq",
            value: "true",
          },
        ],
      ],
    });
    console.log('ngOnInit');
    console.log(this.cartSubTotal);
    console.log(this.cartTotal);
    if (this.cartSubTotal > 0) {
      this.tendersResource.get(params).then((tenders) => {
        this.tender = tenders.items[0];

        this.getFees().then((fees: Array<Fee>) => {
          this.fees = fees;

          this.setCartTotal();
        });
      });
    }
  }

  ngOnDestroy() {
    if (this.cartSub) {
      this.cartSub.unsubscribe();
    }
  }

  getBusiness() {
    return new Promise((resolve, reject) => {
      var data = new ResourceParams({
        urlModifiers: {
          userId: this.user.id,
        },
        expand: ["ownershipType", "physicalAddress.state"],
      });

      this.businessesResource.get(data).then((businesses) => {
        if (businesses.items.length > 0) {
          resolve(businesses.items[0]);
        } else {
          resolve(undefined);
        }
      });
    });
  }

  getFees() {
    return new Promise((resolve) => {
      let params = new ResourceParams({
        $all: true,
        urlModifiers: {
          tenderId: this.tender.id,
        },
      });

      this.tenderFeesResource.get(params).then((fees) => {
        resolve(fees.items);
      });
    });
  }

  setCartTotal() {
    this.cartTotal = 0;
    this.cartSubTotal = 0;

    this.cart.forEach((item) => {
      this.cartTotal += parseFloat(item.cost ? item.cost : 0);
      this.cartSubTotal += parseFloat(item.cost ? item.cost : 0);
    });

    this.fees.forEach((fee: Fee) => {
      this.cartTotal += parseFloat(String(fee.amount ? fee.amount : 0));
    });
  }

  removeCartItem(i) {
    this.cartService.remove(i);

    this.cart = this.cartService.get();
  }
}
