import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { User } from '@towncloud/thor-api';

import { CurrentUserResource } from 'resources';

@Component({
  templateUrl: './profileInformation.html',
  encapsulation: ViewEncapsulation.None
})
export class UserProfileInformationComponent {
  user: User = new User();

  constructor(
    private currentUserResource: CurrentUserResource,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.currentUserResource.get().then(user => {
      this.user = user;
    });
  }

  gotoSettings() {
    this.router.navigate(['../settings'], { relativeTo: this.route });
  }

  gotoPasswordReset(){
    this.router.navigate(['../resetpassword'], { relativeTo: this.route });
  }
}
