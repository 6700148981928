import { Component, ViewEncapsulation } from '@angular/core';

import {
	UsersResource,

	User
} from '@towncloud/thor-api';

import { Field } from 'dataTypes';

import { CurrentUserResource } from 'resources';

import { FormService } from 'services';

@Component({
  templateUrl: './profileResetPassword.html',
  encapsulation: ViewEncapsulation.None
})
export class UserProfileResetPasswordComponent {
	
	user: User = new User();
	fields: any = {};
	showSuccessMessage: boolean = false;

	constructor(
		private currentUserResource: CurrentUserResource,
		private usersResource: UsersResource,
		private formService: FormService
	) { 

		this.currentUserResource.get().then( user => {
			this.user = user;
		})

		// this.fields.currentPassword = new Field({
		// 	type: 'input',
		// 	label: 'Current Password',
		// 	placeholder: 'Enter password',
		// 	subText: 'You must provide your current password in order to change it.',
		// 	required: true
		// })

		this.fields.newPassword = new Field({
			type: 'password',
			label: 'New Password',
			placeholder: 'Minimum 8 characters',
			required: true
		})

		this.fields.confirmPassword = new Field({
			type: 'password',
			label: "Confirm password",
			placeholder: 'Re-enter your password',
			required: true
		})
	}

	formIsValid(){

		if(this.formService.isRequiredFieldsValid(this.fields)
		&& this.fields.newPassword.value.length >= 8
		&& this.fields.newPassword.value == this.fields.confirmPassword.value){
			return true;
		}

		return false;
	}

	updateField(){
		this.showSuccessMessage = false;
	}

	resetPassword(){

		var data = {
			urlModifiers: {
				id: this.user.id
			},
			id: this.user.id,
			password: this.fields.newPassword.value
		}

		this.usersResource.update( data ).then( results =>{
			this.showSuccessMessage = true;

			// this.fields.currentPassword.value = undefined;
			this.fields.newPassword.value = undefined;
			this.fields.confirmPassword.value = undefined;
		})
	}

}
