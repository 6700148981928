import { Injectable } from "@angular/core";
import { DataService } from "./data.service";

import { ResourceParams, DataType } from "dataTypes";

export class ResourceGetResponsePage extends DataType {
  page: number;
  totalCount: number;

  constructor(object: Object = {}) {
    super(object, ["page", "totalCount"]);
  }
}

export class ResourceGetResponse extends DataType {
  items: Array<any>;
  page: ResourceGetResponsePage = new ResourceGetResponsePage();

  constructor(object: Object = {}) {
    super(object, ["items", "page"]);

    if (!object["items"]) {
      this.items = [];
    }

    if (object["page"]) {
      this.page = new ResourceGetResponsePage(object["page"]);
    }
  }

  private _boldResults(filters) {
    this.items.forEach((item) => {
      filters.forEach((filter) => {
        filter.forEach((filterItem) => {
          if (eval("item." + filterItem.property)) {
            var string = this._getBoldedString(
              eval("item." + filterItem.property),
              filterItem.value
            );

            eval("item." + filterItem.property + " = '" + string + "'");
          }
        });
      });
    });
  }

  private _getBoldedString(string, substring) {
    var searchStrLen = substring.length;
    if (searchStrLen == 0) {
      return [];
    }
    var startIndex = 0,
      index,
      indices = [];

    var str = string.toLowerCase();
    var searchStr = substring.toLowerCase();

    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }

    var subStrings = [];

    for (var i = 0; i < indices.length; i++) {
      subStrings.push(
        string.substring(indices[i], indices[i] + substring.length)
      );
    }

    if (indices.length > 0) {
      for (var i = indices.length - 1; i >= 0; i--) {
        if (indices[i] != 0) {
          string =
            string.slice(0, indices[i]) +
            "<b>" +
            string.slice(indices[i], indices[i] + substring.length) +
            "</b>" +
            string.slice(indices[i] + substring.length, string.length);
        } else {
          string =
            "<b>" +
            string.slice(indices[i], indices[i] + substring.length) +
            "</b>" +
            string.slice(indices[i] + substring.length, string.length);
        }
      }
    }

    return string;
  }
}

@Injectable()
export class ResourceService {
  constructor(public dataService: DataService, public url: string) {}

  post(value) {
    return this.dataService.create(this.url, value).then((response) => {
      return response;
    });
  }

  create(value) {
    return this.post(value);
  }

  get(params = new ResourceParams()) {
    return this.dataService.get(this.url, params).then((response) => {
      if (response) {
        if (response.items != undefined) {
          response = new ResourceGetResponse(response);
        }

        if (params.$bold == true) {
          response._boldResults(params.filters);
        }
      } else {
        response = new ResourceGetResponse();
      }

      return response;
    });
  }

  select(id) {
    var params = new ResourceParams({
      filters: [
        [
          {
            property: "id",
            operator: "eq",
            value: id,
          },
        ],
      ],
    });

    return this.dataService.get(this.url, params).then((response) => {
      response = new ResourceGetResponse(response);

      if (response.items.length > 0) {
        return response.items[0];
      }

      return undefined;
    });
  }

  put(value = {}) {
    return this.dataService.update(this.url, value).then((response) => {
      return response;
    });
  }

  update(value = {}) {
    return this.put(value);
  }

  patch(value = {}) {
    return this.dataService.patch(this.url, value).then((response) => {
      return response;
    });
  }

  delete(value = {}) {
    return this.dataService.delete(this.url, value).then((response) => {
      return response;
    });
  }
}
