import { Component, ViewEncapsulation, Input } from '@angular/core';

import { IconSpriteComponent } from './iconSprite.component';

@Component({
  selector: 'tc-icon',
  templateUrl: './icon.html',
  styleUrls: [ './icon.scss' ],
  encapsulation: ViewEncapsulation.None
})

export class IconComponent { 

	_img: string;

	@Input()
	set img(img: string){

		this._img = img;

	}
	get img(): string {
		return this._img;
	}


	constructor() {}

}