import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

import {
  OpportunitiesResource,
  OpportunityTypesResource,

  ResourceParams,
  Opportunity,
  OpportunityType,
  User,
} from '@towncloud/thor-api';

import { CurrentUserResource } from 'resources';

@Component({
  templateUrl: './opportunities.html',
  encapsulation: ViewEncapsulation.None
})
export class OpportunitiesComponent {
  user: User = new User();
  activeCards: any[] = [];
  opportunityType: OpportunityType = new OpportunityType();
  opportunities: Opportunity[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private currentUserResource: CurrentUserResource,
    private opportunityTypesResource: OpportunityTypesResource,
    private opportunitiesResource: OpportunitiesResource,
  ) {
    this.currentUserResource.get().then(user => {
      this.user = user;
    });
  }

  async ngOnInit() {

    this.opportunityType = await this.getOpportunityType();
    this.opportunities = await this.getOpportunities();

  }

  async getOpportunities() {
    const params = new ResourceParams({
      filters: []
    });

    if( this.route.snapshot.queryParams['typeid'] ){

      if(this.route.snapshot.queryParams['status']){
        
        const statuses = this.route.snapshot.queryParams['status'].split(',')
        statuses.forEach( status => {
          params.filters.push([
            {
              property: 'opportunityTypeId',
              operator: 'eq',
              value: this.route.snapshot.queryParams['typeid']
            },
            {
              property: 'status',
              operator: 'eq',
              value: status
            }
          ]);
        });

      }else{
        
        params.filters.push([
          {
            property: 'opportunityTypeId',
            operator: 'eq',
            value: this.route.snapshot.queryParams['typeid']
          }
        ]);

      }

    }else if (this.route.snapshot.queryParams['status']){
      
      const statuses = this.route.snapshot.queryParams['status'].split(',')
      statuses.forEach( status => {
        params.filters.push([
          {
            property: 'status',
            operator: 'eq',
            value: status
          }
        ]);
      });

    }

    const result = await this.opportunitiesResource.get(params);

    return result.items;
  }

  async getOpportunityType() {
    const params = new ResourceParams({
      filters: [
            [
                {
                    property: 'id',
                    operator: 'eq',
                    value: this.route.snapshot.queryParams['typeid']
                }
            ]
      ]
    });

    const result = await this.opportunityTypesResource.get(params);

    return result.items[0];
  }

  navigateToOpportunity(id){
    const navigationExtras: NavigationExtras = {
      relativeTo: this.route
    };
    this.router.navigate(['../', 'opportunities', id], navigationExtras);
  }

}
