import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { CurrentTownService, DocumentsService} from 'services';

import {
  BusinessesResource,
	AttachmentsResource,
	BusinessAttachmentsResource,
	DocumentTypesResource,
  User,
  Business,
  DocumentType,
  BusinessAttachment,
  ResourceParams
} from '@towncloud/thor-api';

import { CurrentUserResource } from 'resources';

import { Field } from 'dataTypes';

import { FormService } from 'services';

@Component({
  templateUrl: './addDocument.html',
  styleUrls: ["./addDocument.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AddDocumentComponent {
  user: User = new User();
  business: Business = new Business();
  documentTypes: Array<DocumentType> = [];
  documentSubscription: Subscription;
  documents: Array<BusinessAttachment> = [];

  fields: any = {
    files: {},
    description: {},
    documentType: {},
    expirationDate: {},
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currentUserResource: CurrentUserResource,
    private businessesResource: BusinessesResource,
    private attachmentsResource: AttachmentsResource,
    private businessAttachmentsResource: BusinessAttachmentsResource,
    private documentTypesResource: DocumentTypesResource,
    private currentTownService: CurrentTownService,
    private documentService: DocumentsService,
    private formService: FormService
  ) {
    this.buildFields();
  }

  async ngOnInit(){
    this.fields.documentType.options = await this.getDocumentTypes();
  }

  ngOnChanges() {
    this.populateForm();
  }

  buildFields() {

    this.fields.files = new Field({
			type: 'file',
			label: 'Documents',
			maxCount: 1,
			preUpload: false,
			required: true,
		});

		this.fields.description = new Field({
			type: 'input',
			label: 'Description',
		});

		this.fields.documentType = new Field({
			type: 'select',
			label: 'Document Type',
			options: [],
			optionLabel: 'name',
      placeholder: 'Select',
			required: true,
		});

		this.fields.expirationDate = new Field({
			type: 'date',
			label: 'Expiration Date',
		});
		
  }

  async getDocumentTypes(){
      const params = new ResourceParams({
        $all: true,
        sort: [
          {
          field: 'name',
          order: 'ASC'
          },
        ],
      });

      let types = await this.documentTypesResource.get(params);

      let results = [];
      types.items.forEach( (i) => {
        results.push( new DocumentType(i));
      });
      return results;

  }

  populateForm() {
		this.fields.files.value = undefined;
		this.fields.description.value = undefined;
		this.fields.documentType.value = undefined;
		this.fields.expirationDate.value = undefined;
  }

  async addDocument() {
		for (let i = 0; i < this.fields.files.value.length; i++) {
			try {
				await this.uploadFile(this.fields.files.value[i]);
			} catch (e) {
				console.log(e);
				this.fields.files.value[i].invalid = true;
			}
		}
    // return to document list
    await this.documentService.updateDocuments();
    this.cancel();
  
	}

  uploadFile(file) {
		return new Promise(async (resolve, reject) => {
			var formData = new FormData();
			formData.append("file", file.file);
      let finalDescription = "";
			file.percentUploaded = 0;

      if (this.fields.description.value !== undefined) {
        finalDescription = this.fields.description.value;
      };

			const params = {
				urlModifiers: {
					businessId: this.route.snapshot.params.businessId,
				},
				file: file,
				documentTypeId: this.fields.documentType.value.id,
        description: finalDescription,
				expirationDate: this.fields.expirationDate.value,
			};

			this.attachmentsResource
				.upload(params)
				.then((result: any) => {
					if (result.status !== 200) {
						reject(result);
					} else {
						resolve(result);
					}
				})
				.catch((e) => {
					console.log(e);
					reject(e);
				});
		});
	}

  isAddDocumentFormValid(){
    let valid = this.formService.isRequiredFieldsValid(this.fields);
		if (valid) {
			this.fields.files.value.forEach((f) => {
				if (f.invalid) {
					valid = false;
					return;
				}
			});
		}

		return valid;
  }

  cancel() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
