import { Component, ViewEncapsulation, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

import { DataService, CartService } from "services";

import {
  BusinessesResource,
  ResourceParams,
  User,
  Business,
  Contact,
} from "@towncloud/thor-api";

import { CurrentUserResource } from "resources";

import { BusinessContactsComponent } from "businessContacts/businessContacts.component";

@Component({
  templateUrl: "./businessDetailContacts.html",
  encapsulation: ViewEncapsulation.None,
})
export class BusinessDetailContactsComponent {
  @ViewChild(BusinessContactsComponent)
  businessContactsComponent: BusinessContactsComponent;

  user: User = new User();
  business: Business = new Business();
  contacts: Contact[] = [];

  valid: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currentUserResource: CurrentUserResource,
    private businessesResourc: BusinessesResource
  ) {}

  async ngOnInit() {
    let params = this.route.snapshot.params;

    this.user = await this.currentUserResource.get();

    this.business = await this.getBusiness(params["businessId"]);
  }

  async getBusiness(businessId) {
    const data = new ResourceParams({
      urlModifiers: {
        userId: this.user.id,
      },
      expand: [
        "ownershipType",
        "physicalAddress.state",
        "mailingAddress.state",
      ],
      filters: [
        [
          {
            property: "id",
            operator: "eq",
            value: businessId,
          },
        ],
      ],
    });

    return (await this.businessesResourc.get(data)).items[0];
  }

  updateContacts() {
    this.businessContactsComponent.applyChanges();
  }
}
