import {
  Component,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import {
  OpportunitiesResource,
  OpportunityTypesResource,
  AttachmentsResource,
  ApiService,
  ResourceParams,
  Opportunity,
  User,
} from "@towncloud/thor-api";

import { environment } from "@environments/environment";

import { CurrentUserResource } from "resources";

@Component({
  templateUrl: "./opportunityDetail.html",
  encapsulation: ViewEncapsulation.None,
})
export class OpportunityDetailComponent {
  @ViewChild("downloadLink") private downloadLink: ElementRef;
  user: User = new User();
  opportunity: Opportunity = new Opportunity();
  publicAttachments: any[] = [];
  privateAttachments: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: ApiService,
    private currentUserResource: CurrentUserResource,
    private opportunitiesResource: OpportunitiesResource,
    private attachmentsResource: AttachmentsResource
  ) {
    this.currentUserResource.get().then((user) => {
      this.user = user;
    });
  }

  async ngOnInit() {
    this.opportunity = await this.getOpportunity();
    this.publicAttachments = await this.getAttachments(false);
    this.privateAttachments = await this.getAttachments(true);
  }

  async getOpportunity() {
    const params = new ResourceParams({
      filters: [
        [
          {
            property: "id",
            operator: "eq",
            value: this.route.snapshot.params["opportunityId"],
          },
        ],
      ],
    });

    const result = await this.opportunitiesResource.get(params);

    return result.items[0];
  }

  async getAttachments(loginRequired) {
    const params = new ResourceParams({
      $all: true,
      urlModifiers: {
        opportunityId: this.route.snapshot.params.opportunityId,
      },
      expand: ["documentType", "opportunityAttachmentDetail"],
      filters: [
        [
          {
            property: "opportunityAttachmentDetail.logInRequired",
            operator: "eq",
            value: loginRequired,
          },
        ],
      ],
    });

    return (await this.attachmentsResource.get(params)).items;
  }

  async downloadAttachment(attachment) {
    const params = new ResourceParams({
      $all: true,
      urlModifiers: {
        opportunityId: attachment.opportunityAttachmentDetail.opportunityId,
        attachmentId: attachment.id,
        file: true,
      },
    });

    this.attachmentsResource.init(params);
    // const ServerWithApiUrl = AppSettings.apiServer + AppSettings.apiUrl;
    const ServerWithApiUrl = environment.thorHostName + "/";
    const url = ServerWithApiUrl + this.attachmentsResource.url;
    const options: any = await this.apiService.getHeader();
    options.responseType = "arraybuffer";

    const file = await this.http.get(url, options).toPromise();

    this.downloadFile(file, attachment.name, "application/text");
  }

  downloadFile(data: any, name: string, type: string) {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);

    const link = this.downloadLink.nativeElement;
    link.href = url;
    link.download = name;
    link.click();

    window.URL.revokeObjectURL(url);
  }
}
