import { Component, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CurrentTownService, FormService } from 'services';

import {
  User
} from '@towncloud/thor-api';

import { CurrentUserResource } from 'resources';

import { Field } from 'dataTypes';

import {
  AccessTokenResource,
  UsersResource,
  ApiService,

  ResourceParams,
  City
} from '@towncloud/thor-api';

@Component({
  selector: 'login',
  templateUrl: './login.html',
  styleUrls: ['./login.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent {
  public town: City = new City();
  public email: Field = new Field();
  public password: Field = new Field();
  public loginFields: any = {};

  public loginFailed: boolean = false;

  public showLogin: boolean = true;
  public showRegistration: boolean = false;
  public showForgotPassword: boolean = false;
  public showPasswordReset: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accessTokenResource: AccessTokenResource,
    private usersResource: UsersResource,
    private currentUser: CurrentUserResource,
    private currentTownService: CurrentTownService,
    private formService: FormService
  ) {
    this.loginFields.email = new Field({
      type: 'input',
      label: 'Email',
      placeholder: 'Enter your email address',
      id: 'login-email',
      required: true
    });

    this.loginFields.password = new Field({
      type: 'password',
      label: 'Password',
      placeholder: 'Enter your password',
      id: 'login-password',
      required: true
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.displayPasswordReset();
      }
    });

    this.currentTownService.get().then(town => {
      this.town = town;
    });
  }

  loginFormIsValid() {
    return this.formService.isRequiredFieldsValid(this.loginFields);
  }

  login() {
    this.setToken(this.loginFields.email.value, this.loginFields.password.value).then(
      () => {
        this.getUser();
      },
      e => {
        this.loginFailed = true;
      }
    );
  }

  setToken(email, password) {
    return new Promise((resolve, reject) => {
      const data = {
        email: email,
        password: password
      };

      this.accessTokenResource.create(data).then(
        response => {
          sessionStorage.setItem('accessToken', JSON.stringify(response));
          ApiService.setToken(response.token);
          resolve();
        },
        e => {
          reject(e);
        }
      );
    });
  }

  getUser() {
    const params = new ResourceParams({
      filters: [
        [
          {
            property: 'email',
            operator: 'eq',
            value: this.loginFields.email.value
          }
        ]
      ]
    });

    this.usersResource.get(params).then(users => {
      if (users.items.length === 1) {
        const user = new User(users.items[0]);

        this.currentUser.set(user);
      } else if (users.items.length === 0) {
        this.loginFailed = true;
      }
    });
  }

  registered(e) {
    this.setToken(e.email, e.password).then(() => {
      this.currentUser.set(new User(e));
    });
  }

  reset(e) {
    this.setToken(e.email, e.password).then(() => {
      this.currentUser.set(new User(e));
    });
  }

  updateField(e: any) {
    this.loginFailed = false;
  }

  displayLogin() {
    this.showLogin = true;
    this.showRegistration = false;
    this.showForgotPassword = false;
    this.showPasswordReset = false;
  }

  displayRegistration() {
    this.showLogin = false;
    this.showRegistration = true;
    this.showPasswordReset = false;
    this.showForgotPassword = false;
  }

  displayForgotPassword() {
    this.showLogin = false;
    this.showForgotPassword = true;
    this.showRegistration = false;
    this.showPasswordReset = false;
  }

  displayPasswordReset() {
    this.showLogin = false;
    this.showRegistration = false;
    this.showForgotPassword = false;
    this.showPasswordReset = true;
  }
}
