import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tc-icon-sprite',
  templateUrl: './iconSprite.html',
  styleUrls: ['./iconSprite.scss'],
  encapsulation: ViewEncapsulation.None
})

export class IconSpriteComponent { 
	
	constructor() {}

}