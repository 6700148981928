import { 
  Component, 
  ViewEncapsulation, 
  Input, 
  Output,
  EventEmitter 
} from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';

import { DataService, CartService } from 'services';

import {
  BusinessContactsResource,
  ContactsResource,

  ResourceParams,
  User,
  Business,
  Contact
} from '@towncloud/thor-api';

@Component({
  selector: 'my-business-contacts',
  templateUrl: './businessContacts.html',
  styleUrls: [ './businessContacts.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class BusinessContactsComponent {
  user: User = new User();

  @Input() business: Business = new Business();
  @Input() contacts: Array<any> = [];

  @Output() contactsChanged: EventEmitter<any> = new EventEmitter();
  
  selectedContact: Contact = new Contact();
  possibleContacts: Contact[] = [];

  showCreateContactModal: boolean = false;
  showContactModal: boolean = false;
  showConfirmDeleteContactModal: boolean = false;
  showConfirmCreateModal: boolean = false;

  formIsValid: boolean = false;

  changes: Array<any> = [];

  constructor(
    private businessContactsResource: BusinessContactsResource,
    private contactsResource: ContactsResource
  ) {}

  async ngOnChanges(){

    if(this.business.id){
      this.contacts = await this.getContacts();
    } 

  }

  async getContacts() {

    var data = new ResourceParams({
      urlModifiers: {
        businessId: this.business.id
      },
      expand: ['contact', 'contact.address.state', 'contact.address.country', 'type']
    });

    return (await this.businessContactsResource.get(data)).items;

  }

  displayCreateContactModal(){
    this.selectedContact = new Contact();
    this.showCreateContactModal = true;
  }

  closeCreateContactModal() {
    this.showCreateContactModal = false;
  }

  displayContactModal(businessContact) {
    this.selectedContact = businessContact.contact;
    this.selectedContact.type = businessContact.type;
    this.showContactModal = true;
  }

  closeContactModal() {
    this.showContactModal = false;
  }

  displayConfirmDeleteContactModal(contact) {
    this.showConfirmDeleteContactModal = true;

    this.selectedContact = contact;
  }

  closeConfirmDeleteContactModal() {
    this.showConfirmDeleteContactModal = false;
  }

  closeConfirmCreateModal(){
    this.showConfirmCreateModal = false;
  }

  async getPossibleMatches(){

    let params = new ResourceParams({
      filters: [
        [
          {
            property: 'name',
            operator: 'eq',
            value: this.selectedContact.name
          }
        ]
      ],
      expand: ['address.state', 'address.country']
    })

    if(this.selectedContact.email){
      params.filters.push([{
        property: 'email',
        operator: 'eq',
        value: this.selectedContact.email
      }])
    }

    if(this.selectedContact.homePhone){
      params.filters.push([{
        property: 'homePhone',
        operator: 'eq',
        value: this.selectedContact.homePhone
      }])
    }

    if(this.selectedContact.cellPhone){
      params.filters.push([{
        property: 'cellPhone',
        operator: 'eq',
        value: this.selectedContact.cellPhone
      }])
    }

    if(this.selectedContact.workPhone){
      params.filters.push([{
        property: 'workPhone',
        operator: 'eq',
        value: this.selectedContact.workPhone
      }])
    }

    if(this.selectedContact.address.address1){
      params.filters.push([{
        property: 'address.address1',
        operator: 'eq',
        value: this.selectedContact.address.address1
      }])
    }

    let results = await this.contactsResource.get(params);

    return results.items;
  }

  async create(){

    this.possibleContacts = await this.getPossibleMatches();

    if(this.possibleContacts.length > 0){

      this.closeCreateContactModal();

      this.showConfirmCreateModal = true;

    }else{
      
      await this.confirmCreate();

      this.closeCreateContactModal();
    }
  }

  async confirmCreate(){

    this.changes.push({type: 'create', contact: JSON.parse(JSON.stringify(this.selectedContact)) });

    this.contacts.push({
      contact: new Contact({

        title: this.selectedContact.title,
        name: this.selectedContact.name,
        preferredName: this.selectedContact.preferredName,

        homePhone: this.selectedContact.homePhone,
        cellPhone: this.selectedContact.cellPhone,
        workPhone: this.selectedContact.workPhone,
        fax: this.selectedContact.fax,
        email: this.selectedContact.email,

        address: {
          address1: this.selectedContact.address.address1,
          address2: this.selectedContact.address.address2,
          city: this.selectedContact.address.city,
          state: this.selectedContact.address.state,
          zipCode: this.selectedContact.address.zipCode,
          countryId: this.selectedContact.address.countryId
        }

      }),
      type: this.selectedContact.type,
      typeId: this.selectedContact.type.id
    })

    this.contactsChanged.emit({type: 'create', contact: this.selectedContact, method: this.createContact });

    this.closeConfirmCreateModal();

  }

  async useExistingContact(contact){

    this.closeCreateContactModal();

    this.closeConfirmCreateModal();

    contact.type = this.selectedContact.type;
    this.selectedContact = contact;

    this.changes.push({type: 'associate', contact: JSON.parse(JSON.stringify(contact)) });

    this.contactsChanged.emit({type: 'associate', contact: this.selectedContact, method: this.createContact });
  }

  async createContact(contact){

    var params: any = {
      title: contact.title,
      name: contact.name,
      preferredName: contact.preferredName,

      homePhone: contact.homePhone,
      cellPhone: contact.cellPhone,
      workPhone: contact.workPhone,
      fax: contact.fax,
      email: contact.email,

      address: {
        address1: contact.address.address1,
        address2: contact.address.address2,
        city: contact.address.city,
        stateId: contact.address.state? contact.address.state.id: undefined,
        stateName: contact.address.stateName,
        zipCode: contact.address.zipCode,
        countryId: contact.address.country? contact.address.country.id: undefined
      }
    }

    let newContact = await this.contactsResource.post(params);
    newContact.type = contact.type;

    return this.associateContact(newContact);

  }

  async associateContact(contact){

    let params = {
      urlModifiers: {
        businessId: this.business.id
      },
      contactId: contact.id,
      typeId: contact.type.id
    }

    return await this.businessContactsResource.post(params);

  }

  async save() {

    this.changes.push({type: 'save', contact: JSON.parse(JSON.stringify(this.selectedContact)) });

    this.contacts.forEach( contact => {
      
      if(contact.contact.id == this.selectedContact.id){

        contact.contact.title = this.selectedContact.title;
        contact.contact.name = this.selectedContact.name;
        contact.contact.preferredName = this.selectedContact.preferredName;

        contact.contact.homePhone = this.selectedContact.homePhone;
        contact.contact.cellPhone = this.selectedContact.cellPhone;
        contact.contact.workPhone = this.selectedContact.workPhone;
        contact.contact.fax = this.selectedContact.fax;
        contact.contact.email = this.selectedContact.email;

        contact.contact.address.address1 = this.selectedContact.address.address1;
        contact.contact.address.address2 = this.selectedContact.address.address2;
        contact.contact.address.city = this.selectedContact.address.city;
        contact.contact.address.stateId = this.selectedContact.address.state? this.selectedContact.address.state.id: undefined;
        contact.contact.address.state = this.selectedContact.address.state;
        contact.contact.address.stateName = this.selectedContact.address.stateName;
        contact.contact.address.zipCode = this.selectedContact.address.zipCode;
        contact.contact.address.countryId = this.selectedContact.address.country? this.selectedContact.address.country.id: undefined;
        contact.contact.address.country = this.selectedContact.address.country;

      }

    })
    
    this.contactsChanged.emit({type: 'update', contact: this.selectedContact, method: this.saveContact });

    this.showContactModal = false;

    return false;

  }

  async saveContact(contact){

    var params: any = new ResourceParams({
      urlModifiers: {
        businessId: this.business.id
      },
      filters: [
        [
          {
            property: 'contactId',
            operator: 'eq',
            value: contact.id
          }
        ]
      ]
    });

    let results = (await this.businessContactsResource.get(params)).items;

    if(results.length > 0){

      params = {
        urlModifiers: {
          businessId: this.business.id,
          id: results[0].id
        },
        typeId: this.selectedContact.type.id
      }

      await this.businessContactsResource.put(params);

    }

    params = {
      urlModifiers: {
        id: contact.id
      },
      title: contact.title,
      name: contact.name,
      preferredName: contact.preferredName,

      homePhone: contact.homePhone,
      cellPhone: contact.cellPhone,
      workPhone: contact.workPhone,
      fax: contact.fax,
      email: contact.email,

      address: {
        address1: contact.address.address1,
        address2: contact.address.address2,
        city: contact.address.city,
        stateId: contact.address.stateId,
        stateName: contact.address.stateName,
        zipCode: contact.address.zipCode,
        countryId: contact.address.countryId
      }

    };

    return await this.contactsResource.update(params);

  }

  async delete() {

    this.changes.push({type: 'delete', contact: JSON.parse(JSON.stringify(this.selectedContact)) });

    this.contacts.forEach( (contact, index) => {
      if(contact.id == this.selectedContact.id){
        this.contacts.splice(index, 1);
      }
    })

    this.contactsChanged.emit({type: 'remove', contact: this.selectedContact, method: this.deleteContact });

    this.showConfirmDeleteContactModal = false;

    return false;

  }

  async deleteContact(contact){

    var params = {
      id: contact.id
    };

    await this.businessContactsResource.delete(params);

  }

  async applyChanges(){

    for(let i = 0; i < this.changes.length; i++){

      let change = this.changes[i];

      switch(change.type){
        case 'create':
            await this.createContact(change.contact);
          break;
        case 'save':
            await this.saveContact(change.contact);
          break;
        case 'associate':
            await this.associateContact(change.contact);
          break;
        case 'delete':
            await this.deleteContact(change.contact);
          break;
        default:
      }

    }

    this.changes = [];

    this.contacts = await this.getContacts();

  }

}
