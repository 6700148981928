import { Component, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Subscription } from "rxjs";

import { User } from "@towncloud/thor-api";

import { CurrentUserResource } from "resources";

import { CurrentUserService, CurrentTownService } from "services";

@Component({
  selector: "view",
  templateUrl: "./view.html",
  styleUrls: ["./view.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ViewComponent {
  isLoggedIn: boolean;
  town: string;
  config: any;
  routeFound: boolean;
  invalidRoute: boolean;
  routePermission: string;
  townInRoute: boolean;
  routeData: any;
  menu: Array<any>;
  permissions: Array<any> = [];
  applications: Array<any> = [];
  townSubscription: Subscription;

  user: User = new User();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private currentUser: CurrentUserResource,
    private currentUserService: CurrentUserService,
    private currentTownService: CurrentTownService
  ) {
    this.currentTownService.setTown(this.route.snapshot.params.town);

    this.getCurrentUser();

    router.events.subscribe((event) => {
      this.currentTownService.setActiveRoute(this.route);
    });
  }

  ngOnInit() {
    this.townSubscription = this.currentTownService
      .getChangeEmitter()
      .subscribe((town) => {
        if(town){
          this.town = town.slug;
        }
      });

    this.route.params.forEach((params: Params) => {
      this.townInRoute = false;
      if (params["town"]) {
        this.townInRoute = true;
      }
    });

    this.currentTownService.get().then((town) => {
      if (town) {
        this.town = town.slug;
      }
    });
  }

  ngOnDestroy() {
    if (this.townSubscription) {
      this.townSubscription.unsubscribe();
    }
  }

  routeExists() {
    if (this.town === undefined && this.townInRoute) {
      return false;
    }

    return true;
  }

  hasPermission() {
    if (this.permissions && this.routePermission !== undefined) {
      var result = false;

      this.permissions.forEach((item) => {
        if (item.permission === this.routePermission) {
          result = true;

          return true;
        }
      });

      return result;
    }

    return true;
  }

  getCurrentUser() {
    this.currentUser.get().then((user) => {
      this.user = user;

      if (!this.user.id) {
        return false;
      }

      this.currentUser.getPermissions().then((permissions: any) => {
        this.permissions = permissions.items;
      });
    });
  }

  onUserChange() {
    this.getCurrentUser();
  }
}
