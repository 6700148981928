import { Injectable } from '@angular/core';

import { ResourceParamsSort } from 'dataTypes';

@Injectable()
export class MockDataService {

	private map: any = {};

	constructor(){
	}

	create(data, value): Promise<any> {

		value.id = data.length + 1;
		data.push(value);

		return Promise.resolve(value);
	}

	get(DATA, params): Promise<any> {

		var results = this.find(DATA.mockData, params.filters);
		results = this.sort(results, params.sort);

		var response = {
			items: this.getPage(results, params),
			page: {
				totalCount: results.length
			}
		}

		if(params.expands){

			response.items.forEach( (item) => {
				params.expands.forEach( (value) => {

					var field = DATA.mockExpands[value];
					var filter = {};
					filter['id'] = item[field.field];

					item[value] = this.find(field.data, [filter])[0];
				});
			});			
		}

		response = JSON.parse(JSON.stringify(response));

	    return Promise.resolve(response);
	}

	update(data, value): Promise<any> {

		var result = undefined;

		data.forEach( function(item, index){

			if( item.id == value.id ){
				data[index] = value;
				result = value;
			}

		});

		return Promise.resolve(result);
	}

	delete(){

	}

	private find(data, filters = []){
		// filters = [ {name: 'test', number: '123'}, { } ]
		// (AND AND AND) OR (AND AND AND)

		function removeDuplicates(arr, prop) {
			var new_arr = [];
			var lookup  = {};

			for (var i in arr) {
				lookup[arr[i][prop]] = arr[i];
			}

			for (i in lookup) {
				new_arr.push(lookup[i]);
			}

			return new_arr;
		}

		var results = [];

		if(filters.length > 0){
			filters.forEach(function(filter){
				data.forEach( function(item){
					var match = true;

					for (var field in filter){

						if(isNaN(filter[field])){
							if(eval('item.'+field) != undefined){
								if(
									eval('item.'+field).toString().toLowerCase().indexOf(filter[field]['value'].toLowerCase()) == -1 
									&& filter[field] != undefined && filter[field] != '' 
								){
									match = false;
								}
							}else{
								match = false
							}
							
						}else{

							if(
								item[field].toString() != filter[field].toString()
							){
								match = false;
							}
						}
					}

					if(match){
						results.push(item);
					}
				});
			})

			results = removeDuplicates(results, 'id');

		}else{
			results = data;
		}

		return results;
	}

	private sort(data, sort: ResourceParamsSort = new ResourceParamsSort ){

		function compare(a,b) {
			if(sort.order == 'ASC'){
				if (a[sort.field] < b[sort.field])
					return -1;
				if (a[sort.field] > b[sort.field])
					return 1;
				return 0;
			}else if(sort.order == 'DESC'){
				if (a[sort.field] > b[sort.field])
					return -1;
				if (a[sort.field] < b[sort.field])
					return 1;
				return 0;
			}			
		}

		return data.sort(compare);

	}

	private getPage(data, params){
		var results = [];

		if(params.offset != undefined && params.limit != undefined){

			for(var i = params.offset; i < params.offset + params.limit; i++ ){
				if(data[i]){
					results.push(data[i]);
				}				
			}

		}else{
			results = data;
		}

		return results;
	}
}