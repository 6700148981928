import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";

import {
  User,
  Application,
} from "@towncloud/thor-api";

import { CurrentUserResource, CityApplicationsResource } from "resources";

import { ResourceParams as StarkResourceParams } from "dataTypes";

import { CurrentTownService } from "../services/currentTown.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private currentUserResource: CurrentUserResource,
    private cityApplicationsResource: CityApplicationsResource
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user: User = await this.currentUserResource.get();

    if (!user.id) {
      // const applications: Array<Application> = await this.getCityApplications(
      //   route.paramMap.get("town")
      // );

      const town: string = route.paramMap.get("town");
      this.router.navigate([town, "login"]);

      return false;
    } else {
      return true;
    }
  }

  async getCityApplications(slug) {
    const params = new StarkResourceParams({
      $header: {
        "x-city": slug
      }
    });

    const result = await this.cityApplicationsResource.get(params);
    return result;
  }

  hasAllowedApplication(applications) {
    const allowedApplications = ["Procurement"];

    for (let i = 0; i < applications.length; i++) {
      for (let k = 0; k < allowedApplications.length; k++) {
        if (allowedApplications[k] === applications[i].name) {
          return true;
        }
      }
    }

    return false;
  }
}
