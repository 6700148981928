import { Component, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {
  PasswordResource,
  CurrentUserResource
} from 'resources';

import { Field } from 'dataTypes';

import {
  FormService,
  CurrentTownService
} from 'services';

@Component({
  selector: 'password-reset',
  templateUrl: './passwordReset.html'
})
export class PasswordResetComponent {

  @Output() reset: EventEmitter<any> = new EventEmitter();

  token: string;

  fields:any = {
    password: new Field(),
    password2: new Field()
  }

  resetSuccessFull: boolean = false;

  errorMessage: string;

  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private passwordResource: PasswordResource,
    private formService: FormService,
    private currentUser: CurrentUserResource,
    private currentTownService: CurrentTownService
  ) {

    this.fields.password = new Field({
      type: 'password',
      label: 'Password',
      id: 'password',
      required: true
    });

    this.fields.password2 = new Field({
      type: 'password',
      label: 'Confirm Password',
      id: 'password2',
      required: true
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.token = params['token'];
      }
    });
  }

  formIsValid(){
    if(!this.formService.isRequiredFieldsValid(this.fields)){
      return false;
    }

    if(this.fields.password.value != this.fields.password2.value){
      return false;
    }

    return true;
  }

  async resetPassword() {
    const data = {
      urlModifiers:{
        id: this.token
      },
      password: this.fields.password.value
    }

    this.passwordResource.put(data).then( async (r) => {
      console.log(r);
      console.log('this worked... probably');

      if(r.id){

        r.password = this.fields.password.value;

        this.reset.emit(r);

      }

    }, (e) => {
      console.log(e);
      console.log('there was an error.');
      if(e.status == 404){
        this.errorMessage = 'The token is invalid or has expired.';
      }

    });
  }

}
