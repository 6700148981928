import { Component, ViewEncapsulation } from '@angular/core';

import {
  UsersResource,
  User
} from '@towncloud/thor-api';

import { Field } from 'dataTypes';

import { CurrentUserResource } from 'resources';

import { FormService } from 'services';

@Component({
  templateUrl: './profileSettings.html',
  encapsulation: ViewEncapsulation.None
})
export class UserProfileSettingsComponent {
  user: User = new User();
  fields: any = {};
  showSuccessMessage: boolean = false;

  constructor(
    private currentUserResource: CurrentUserResource,
    private usersResource: UsersResource,
    private formService: FormService
  ) {
    this.currentUserResource.get().then(user => {
      this.user = user;

      this.loadUserData();
    });

    this.fields.firstName = new Field({
      type: 'input',
      label: 'First Name',
      required: true
    });

    this.fields.lastName = new Field({
      type: 'input',
      label: 'Last Name',
      required: true
    });

    this.fields.email = new Field({
      type: 'input',
      label: 'Email',
      required: true
    });
  }

  loadUserData() {
    this.fields.firstName.value = this.user.firstName;
    this.fields.lastName.value = this.user.lastName;
    this.fields.email.value = this.user.email;
  }

  formIsValid() {
    return this.formService.isRequiredFieldsValid(this.fields);
  }

  updateField() {
    this.showSuccessMessage = false;
  }

  save() {
    var data = {
      urlModifiers: {
        id: this.user.id
      },
      id: this.user.id,
      firstName: this.fields.firstName.value,
      lastName: this.fields.lastName.value,
      email: this.fields.email.value
    };

    this.usersResource.update(data).then(user => {
      this.currentUserResource.set(user);

      this.showSuccessMessage = true;
    });
  }
}
