import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import {
  BusinessesResource,
  UsersResource,
  OwnershipTypesResource,

  ResourceParams,
  Business,
  OwnershipType,
} from '@towncloud/thor-api';

import {
  Field
} from 'dataTypes';

import {
  CurrentUserResource,
} from 'resources';

import { FormService } from 'services';

@Component({
  selector: 'my-business',
  templateUrl: './businessDetail.html',
  encapsulation: ViewEncapsulation.None
})
export class BusinessDetailComponent {
  @Input() business: Business = new Business();

  @Input() valid: boolean = false;
  @Output() validChange = new EventEmitter();

  @Output() businessChange: EventEmitter<any> = new EventEmitter();

  fields: any = {};
  physicalAddress: any = {};
  mailingAddress: any = {};
  _business: Business = new Business();
  physicalAddressIsValid: boolean;

  constructor(
    private businessesResource: BusinessesResource,
    private usersResource: UsersResource,
    private currentUserResource: CurrentUserResource,
    private ownershipTypesResource: OwnershipTypesResource,
    private formService: FormService
  ) {
    this.buildFields();
  }

  async ngOnInit(){

    this.fields.ownershipType.options = await this.getOwnershipTypes();

    setTimeout( () => {
      this.setFormValidation();
    })
  }

  ngOnChanges() {
    if (this._business.id !== this.business.id) {

      this._business = JSON.parse(JSON.stringify(this.business));

      this.populateForm();

      this.setFormValidation();
    }
  }

  buildFields(){
    this.fields.businessName = new Field({
      type: 'input',
      label: 'Business Name',
      placeholder: 'Legal name of the business',
      required: true
    });

    this.fields.doingBusinessAs = new Field({
      type: 'input',
      label: 'Doing business as',
      placeholder: 'Common name of the business',
      required: true
    });

    this.fields.ownershipType = new Field({
      type: 'select',
      label: 'Ownership Type',
      options: [],
      optionLabel: 'code',
      placeholder: 'Select'
    });

    this.fields.stateRegistrationNumber = new Field({
      type: 'input',
      label: 'State registration / License number',
      placeholder: 'State registration or License number'
    });

    this.fields.tin = new Field({
      type: 'input',
      label: 'Tax ID number',
      placeholder: 'The number you use to file your taxes'
    });

    this.fields.phone = new Field({
      type: 'input',
      label: 'Phone',
      placeholder: '###-###-####'
    });

    this.fields.fax = new Field({
      type: 'input',
      label: 'Fax',
      placeholder: '###-###-####'
    });

    this.fields.website = new Field({
      type: 'input',
      label: 'Website',
      placeholder: 'http://example.com'
    });

    this.fields.physicalAddress = {};

    this.fields.mailingAddress = {};
  }

  async getOwnershipTypes() {
    var params = new ResourceParams({
      $all: true
    });

    let types = [];
    
    (await this.ownershipTypesResource.get(params)).items.forEach( (type) => {
      types.push( new OwnershipType(type));
    })

    return types;
    
  }

  populateForm(){

    this.fields.businessName.value = this._business.name;
    this.fields.doingBusinessAs.value = this._business.doingBusinessAs;
    this.fields.ownershipType.value = this._business.ownershipType;
    this.fields.stateRegistrationNumber.value = this._business.stateRegistrationNumber;
    this.fields.tin.value = this._business.taxIdetificationNumber;
    this.fields.phone.value = this._business.phone;
    this.fields.fax.value = this._business.fax;
    this.fields.website.value = this._business.website;

    this.physicalAddress.address1 = this._business.physicalAddress.address1;
    this.physicalAddress.address2 = this._business.physicalAddress.address2;
    this.physicalAddress.city = this._business.physicalAddress.city;
    this.physicalAddress.state = this._business.physicalAddress.state;
    this.physicalAddress.stateName = this._business.physicalAddress.stateName;
    this.physicalAddress.zipCode = this._business.physicalAddress.zipCode;
    this.physicalAddress.country = this._business.physicalAddress.country;

    this.mailingAddress.address1 = this._business.mailingAddress.address1;
    this.mailingAddress.address2 = this._business.mailingAddress.address2;
    this.mailingAddress.city = this._business.mailingAddress.city;
    this.mailingAddress.state = this._business.mailingAddress.state;
    this.mailingAddress.stateName = this._business.mailingAddress.stateName;
    this.mailingAddress.zipCode = this._business.mailingAddress.zipCode;
    this.mailingAddress.country = this._business.mailingAddress.country;

  }

  setFormValidation(){

    if(this.formService.isRequiredFieldsValid(this.fields) && this.physicalAddressIsValid){
      this.valid = true;
    }else{
      this.valid = false;
    }

    this.validChange.emit(this.valid);
  }

  fieldChanged() {
    this._business.name = this.fields.businessName.value;
    this._business.doingBusinessAs = this.fields.doingBusinessAs.value;
    this._business.ownershipType = this.fields.ownershipType.value;
    this._business.ownershipTypeId = this.fields.ownershipType.value? this.fields.ownershipType.value.id: undefined;
    this._business.stateRegistrationNumber = this.fields.stateRegistrationNumber.value;
    this._business.taxIdetificationNumber = this.fields.tin.value;
    this._business.phone = this.fields.phone.value;
    this._business.fax = this.fields.fax.value;
    this._business.website = this.fields.website.value;

    this.fields.physicalAddress = {
      address1: this._business.physicalAddress.address1,
      address2: this._business.physicalAddress.address2,
      city: this._business.physicalAddress.city,
      state: this._business.physicalAddress.stateId,
      country: this._business.physicalAddress.countryId
    };

    this._business.physicalAddress.address1 = this.physicalAddress.address1;
    this._business.physicalAddress.address2 = this.physicalAddress.address2;
    this._business.physicalAddress.city = this.physicalAddress.city;
    this._business.physicalAddress.state = this.physicalAddress.state;
    this._business.physicalAddress.stateId = this.physicalAddress.state? this.physicalAddress.state.id: undefined;
    this._business.physicalAddress.stateName = this.physicalAddress.stateName;
    this._business.physicalAddress.zipCode = this.physicalAddress.zipCode;
    this._business.physicalAddress.country = this.physicalAddress.country;
    this._business.physicalAddress.countryId = this.physicalAddress.country? this.physicalAddress.country.id: undefined;

    this._business.mailingAddress.address1 = this.mailingAddress.address1;
    this._business.mailingAddress.address2 = this.mailingAddress.address2;
    this._business.mailingAddress.city = this.mailingAddress.city;
    this._business.mailingAddress.state = this.mailingAddress.state;
    this._business.mailingAddress.stateId = this.mailingAddress.state? this.mailingAddress.state.id: undefined;
    this._business.mailingAddress.stateName = this.mailingAddress.stateName;
    this._business.mailingAddress.zipCode = this.mailingAddress.zipCode;
    this._business.mailingAddress.country = this.mailingAddress.country;
    this._business.mailingAddress.countryId = this.mailingAddress.country? this.mailingAddress.country.id: undefined;

    this.setFormValidation();
    
    this.businessChange.emit(this._business);
  }

}
