import { EventEmitter } from '@angular/core';

export class FormService {

	constructor(){}

	isRequiredFieldsValid(fields){

		for(var prop in fields){
			if(fields[prop].required){
				if( fields[prop].type === "multiple" ){

					for(let i = 0; i < fields[prop].fields.length; i++){
						if(!this.valueIsSet(fields[prop].fields[i].value)){
							return false;
						}
					}
				
				}else if (fields[prop].type === "file" ) {
					if (!this.valueIsSet(fields[prop].value) || fields[prop].value.length === 0) {
						return false;
					}
				}else if ( !this.valueIsSet(fields[prop].value) ) {
					return false;
				}

			}
		}

		return true;
	}

	private valueIsSet(value){
		if(value == undefined){
			return false;
		}else if(typeof value == 'string'){
			
			if(value.trim().length == 0){
				return false;
			}
		}

		return true;
	}

}