import { Component, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

import { DataService, CartService, CurrentTownService } from "services";

import {
  BusinessesResource,
  BusinessLicensesResource,
  ItemsResource,
  BusinessLicenseListResource,
  ReceiptLineListResource,
  AttachmentsResource,
  BusinessAttachmentsResource,
  LicenseTypeDocumentTypesResource,
  LicenseTypesResource,
  ResourceParams,
  User,
  Business,
  License,
  ReceiptItem,
  LicenseType,
} from "@towncloud/thor-api";

import { CurrentUserResource } from "resources";

@Component({
  templateUrl: "./businessLicenses.html",
  styleUrls: ["./businessLicenses.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BusinessLicensesComponent {
  user: User = new User();
  business: Business = new Business();
  licenses: Array<any> = [];
  receiptLines: Array<any> = [];
  cart: Array<any> = [];
  licenseTypes: Array<LicenseType> = [];
  // pendingItems: Array<any> = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currentUserResource: CurrentUserResource,
    private businessesResource: BusinessesResource,
    private businessLicensesResource: BusinessLicensesResource,
    private cartService: CartService,
    private itemsResource: ItemsResource,
    private currentTownService: CurrentTownService,
    private businessLicenseListResource: BusinessLicenseListResource,
    private receiptLineListResource: ReceiptLineListResource,
    private attachmentsResource: AttachmentsResource,
    private businessAttachmentsResource: BusinessAttachmentsResource,
    private licenseTypeDocumentTypesResource: LicenseTypeDocumentTypesResource,
    private licenseTypesResource: LicenseTypesResource,
  ) {
    this.cart = this.cartService.get();
  }

  async ngOnInit() {
    let params = this.route.snapshot.params;

    this.user = await this.currentUserResource.get();

    this.business = await this.getBusiness(params["businessId"]);

    this.licenseTypes = await this.getLicenseTypes();

    this.getLicenses().then((licenses: Array<License>) => {
      this.licenses = licenses;

      this.checkForRequiredDocuments();

      this.getReceiptLines().then((lines: Array<any>) => {
        this.receiptLines = lines;
      });

      // this.getPendingItems();
    });
  }

  async getBusiness(businessId) {
    const data = new ResourceParams({
      urlModifiers: {
        userId: this.user.id,
      },
      expand: [
        "ownershipType",
        "physicalAddress.state",
        "mailingAddress.state",
      ],
      filters: [
        [
          {
            property: "id",
            operator: "eq",
            value: businessId,
          },
        ],
      ],
    });

    return (await this.businessesResource.get(data)).items[0];
  }

  async getLicenseTypes() {
    const data = new ResourceParams({
      urlModifiers: {
        userId: this.user.id,
      },
      filters: [
        [
          {
            property: "eligibleToCreateOnline",
            operator: "eq",
            value: true,
          },
        ],
      ],
    });

    return (await this.licenseTypesResource.get(data)).items;
  }

  getLicenses() {
    return new Promise((resolve, reject) => {
      var params = new ResourceParams({
        expand: ["type","business"],
        filters: [
          [
            {
              property: "business.id",
              operator: "eq",
              value: this.business.id,
            },
          ],
        ],
        sort: [
          {
            field: "year",
            order: "DESC",
          },
        ],
      });

      this.businessLicenseListResource.get(params).then((licenses) => {
        resolve(licenses.items);
      });
    });
  }

  getReceiptLines() {
    return new Promise((resolve) => {
      var licenses = [];

      this.licenses.forEach((license) => {
        licenses.push(license.id);
      });

      var params = new ResourceParams({
        $all: true,
        filters: [
          [
            {
              property: "businessLicenseId",
              operator: "in",
              value: "[" + licenses.join(",") + "]",
            },
          ],
        ],
      });

      this.receiptLineListResource.get(params).then((receiptLines) => {
        resolve(receiptLines.items);
      });
    });
  }

  // getPendingItems() {
  //   console.log("====businessLicense component - getPendingItems - licenses====");
  //   console.log(this.licenses);
  //   this.pendingItems = this.licenses.filter(
  //     (pendingItem) => pendingItem.status === "Pending"
  //   );
  // }

  addToCart(license) {
    var params = new ResourceParams({
      expand: ["application"],
      filters: [
        [
          {
            property: "application.name",
            operator: "eq",
            value: "Licenses",
          },
        ],
      ],
    });

    this.itemsResource.get(params).then((items) => {
      const item = items.items[0];
      const licenseFeeTotal = license.exemptFromFee ? 0 : license.feeTotal;

      const receiptItem = new ReceiptItem({
        itemId: item.id,
        item: item,
        chargeItem: item,
        quantity: 1,
        cost: licenseFeeTotal,
        businessLicenseId: license.id,
        businessLicense: license,
      });

      this.cartService.add(receiptItem);

      this.cart = this.cartService.get();
    });
  }

  viewReceipt(license) {
    this.router.navigate([license.id, "receipt"], { relativeTo: this.route });
  }

  viewCertificate(license) {
    this.router.navigate([license.id, "certificate"], {
      relativeTo: this.route,
    });
  }

  itemIsInCart(item) {
    let inCart = false;

    this.cart.forEach((c) => {
      if (c.businessLicense.id === item.id) {
        inCart = true;

        return;
      }
    });

    return inCart;
  }

  hasReceipt(license) {
    let found = false;
    this.receiptLines.forEach((line) => {
      if (line.businessLicenseId === license.id) {
        found = true;
      }
    });

    return found;
  }

  navigateToBusiness() {
    this.currentTownService.get().then((town) => {
      this.router.navigate([town.slug, "mybusiness"]);
    });
  }

  navigateToAddLicense() {
    this.router.navigate(["add"], { relativeTo: this.route });
  }

  navigateToBusinessDocuments() {
    this.currentTownService.get().then((town) => {
      this.router.navigate([town.slug, "mybusiness", this.business.id, "documents"]);
    });
  }

  checkForRequiredDocuments() {
    this.licenses.forEach((license) => {
      license.requiredDocumentTypes = [];

      if (license.status === 'Pending') {
        let businessDocuments: any = {};
        let licenseDocumentTypes: any = {};
        this.getDocuments(license.business.id).then((documents) => {
          businessDocuments = documents;
  
          this.getLicenseTypeDocuments(license.type.id).then((documentTypes) => {
            licenseDocumentTypes = documentTypes;
  
            if (licenseDocumentTypes && licenseDocumentTypes.length > 0) {
              licenseDocumentTypes.forEach((requiredDocumentType) => {
                let requirementMet = false;
                if (businessDocuments && businessDocuments.length > 0) {
                  businessDocuments.forEach((businessDocument) => {
                    if (businessDocument.documentType.id === requiredDocumentType.documentType.id) {
                      requirementMet = true;
                      return;
                    }
                  });
                }
                if (!requirementMet) {
                  license.requiredDocumentTypes.push(requiredDocumentType);
                }
              });
            } 
          });
        });
      }

      


    });
  }

  getDocuments(businessId) {
    return new Promise((resolve, reject) => {
      const params = new ResourceParams({
        urlModifiers: {
          businessId: businessId,
      },
        expand: ['documentType', 'businessAttachmentDetail'],
      });

      this.attachmentsResource.get(params).then((documents) => {
        resolve(documents.items);
      });
    });
  }

  getLicenseTypeDocuments(licenseTypeId) {
    return new Promise((resolve, reject) => {
      let params = new ResourceParams({
        urlModifiers: {
          licenseTypeId: licenseTypeId
        },
        expand: ['documentType'],
      });

      this.licenseTypeDocumentTypesResource.get(params).then((documentTypes) => {
        resolve(documentTypes.items);
      });
    });
  }
}
