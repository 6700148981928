import { Injectable } from '@angular/core';

import { ResourceParams } from 'dataTypes';

import { MockDataService } from './mockData.service';
import { ApiService } from './api.service';

export var DATA = {};

export var offLine: boolean = false;

@Injectable()
export class DataService {

	private map: any = {};

	constructor(
		private mockDataService: MockDataService,
		private apiService: ApiService
	){
		this.map = {
			'users': 'Users'
		}
	}

	isOffline(){
		return offLine;
	}

	setOffline(state: boolean){
		offLine = state;
	}

	post(data, value): Promise<any> {

		return new Promise( (resolve, reject) => {

			if(!offLine){

				var url = DATA[data].url;

				for(var param in value.urlModifiers){
					url = url.replace(':'+param, value.urlModifiers[param]);

					if(param == 'id'){
						url += '/'+ value.urlModifiers[param];
					}
				}

				delete value.urlModifiers;

				this.apiService.create(url.toLowerCase(), value).then( results => {
			    	resolve(results);
			    }, e => {
			    	reject(e);
			    });
			}else{

				if(DATA[url.toLowerCase()] != undefined){
					this.mockDataService.create(DATA[url.toLowerCase()].mockData, value).then( results => {
				    	resolve(results);
				    }, e => {
				    	reject(e);
				    });
				}else{
					resolve(undefined);
				}			
			}

		})
			
	}

	create(data, value): Promise<any> {
		return this.post(data, value);
	}

	get(data, params:ResourceParams = new ResourceParams ): Promise<any> {
		if(!offLine){
			
			var queryParams = this.buildQueryString(params);

			var url = DATA[data].url;

			for(var param in params.urlModifiers){

				url = url.replace(':'+param, params.urlModifiers[param]);
				
			}
			
			return this.apiService.get(url.toLowerCase()+queryParams).then( results => {

				if(params['$all']){
					params.limit = results.page.totalCount;

					var queryParams = this.buildQueryString(params);
					
					return this.apiService.get(url.toLowerCase()+queryParams).then( results => {
						return Promise.resolve(results);
					});
				}else{
					return Promise.resolve(results);
				}
		    	
		    });
		}else{

			if(DATA[data] != undefined){
				return this.mockDataService.get(DATA[data], params).then( results => {
			    	return Promise.resolve(results);
			    });
			}else{
				return Promise.resolve(undefined);
			}			
		}	    
	}

	buildQueryString(params){

		var queryParams = '?offset='+params.offset+'&limit='+params.limit;

		if(params.sort){
			if(params.sort.length > 0){
				var sortString = '';

				params.sort.forEach( value=> {
					if(sortString.length > 0){
						sortString += ",";
					}

					sortString += value.field+':'+value.order;

				})

				queryParams += '&sortby='+sortString;
			}				
		}

		if(params.filters){
			var filterString = '';

			params.filters.forEach(filter => {

				var fieldString = '';

				filter.forEach( field => {

					if(fieldString.length > 0){
						fieldString += ',';
					}

					fieldString += field.property +':'+field.operator+':' + field.value;
				})

				if(fieldString.length > 0){
					fieldString =  "(" + fieldString + ")"
				}

				if(filterString.length > 0){
					filterString += ',';
				}

				filterString += fieldString;
			})

			queryParams += "&filter="+encodeURIComponent(filterString);
		}

		if(params.expand){
			queryParams += "&expand="+params.expand;
		}

		return queryParams;
	}

	put(data, value): Promise<any> {

		if(!offLine){

			var params = new ResourceParams({})

			if(value.urlModifiers){
				
				params['urlModifiers'] = value.urlModifiers;

				if(value.urlModifiers.id){
					params['filters'] = [
						[
							{
								property: 'id',
								operator: 'eq',
								value: value.urlModifiers.id
							}
						]
					]
				}
			}


			return this.get( data, params ).then ( results => {

				if(results.items != undefined){
					var item = results.items[0];
				}else{
					var item = results;
				}

				for(var prop in item){

					item[prop] = prop in value ? value[prop] : item[prop];
				}

				for(var prop in value){
					if(prop != 'urlModifiers'){
						item[prop] = value[prop];
					}					
				}

				var url = DATA[data].url;

				for(var param in value.urlModifiers){

					url = url.replace(':'+param, value.urlModifiers[param]);

				}

				if(value.urlModifiers){
					if(value.urlModifiers.id){
						url += '/' + value.urlModifiers.id
					}
				}

				return this.apiService.update( url.toLowerCase(), item).then( results => {
			    	return Promise.resolve(results);
			    })

			})

		}else{
			
		}
	}

	update(data, value): Promise<any> {
		return this.put(data, value);
	}

	patch(data, value): Promise<any> {

		if(!offLine){

			var url = DATA[data].url;

			for(var param in value.urlModifiers){
				url = url.replace(':'+param, value.urlModifiers[param]);
			}

			delete value.urlModifiers;

			for(var param in value){
				url = url.replace(':'+param, value[param]);
			}

			if(value['id']){
				url += '/'+value['id'];
			}

			return this.apiService.patch(url.toLowerCase(), value).then( results => {
		    	return Promise.resolve(results);
		    });
		}else{

			return Promise.resolve(undefined);		
		}	
	}

	public delete(data, value){
		if(!offLine){

			var url = DATA[data].url;

			for(var param in value){
				url = url.replace(':'+param, value[param]);
			}

			return this.apiService.delete(url.toLowerCase() + '/' + value.id).then( results => {
		    	return Promise.resolve(results);
		    });
		}else{

			// if(DATA[url.toLowerCase()] != undefined){
			// 	return this.mockDataService.delete(DATA[url.toLowerCase()].mockData, value).then( results => {
			//     	return Promise.resolve(results);
			//     });
			// }else{
			// 	return Promise.resolve(undefined);
			// }			
		}	
	}
}