export class ApiError {

	error: any;
	headers: any;
	message: string;
	name: string;
	ok: boolean;
	status: number;
	statusText: string;
	url: string;

	constructor(object: Object = {}){
		var properties = [
			'error',
			'headers',
			'message',
			'name',
			'ok',
			'status',
			'statusText',
			'url'
		];
		
		for( let prop in object){

			properties.forEach( (p) => {
				if(p == prop){
					this[prop] = object[prop];
				}
			})			
		}
	}
}