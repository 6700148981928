export class DataType {

	constructor(object: Object = undefined, properties: Array<string> = [], map = undefined){

		if(object){
			for(var property in object){
				
				this[property] = this._mapProperty(properties, property, object, map);

			}
		}
	}

	private _mapProperty(properties, property, object, map){

		var resourceProperty = property;

		if(map){
			resourceProperty = map[property];
		}

		if( this._hasProperty(properties, property, object) ){
			return object[resourceProperty]
		}

		return undefined
	}

	private _hasProperty(properties, property, object){

		if(properties.indexOf(property) == -1){
			console.error('Invalid property of "' + property + '" for Data Type');
			return false;
		}

		return true
	}

	clone(): any{

		var clone = Object.create(this);

		for (var attribute in this) {
	        if (typeof this[attribute] === "object") {
	            clone[attribute] = this.clone();
	        } else {
	            clone[attribute] = this[attribute];
	        }
	    }

	    return clone;
	}
}