import { Component, ViewEncapsulation, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import {
  BusinessesResource,
  LicensesResource,
  LicenseTypesResource,
  BusinessLicenseListResource,
  CitiesResource,
  ReceiptLineListResource,
  LicenseCertificateSettingsResource,

  ResourceParams,
  License,
  LicenseType,
  Business,
  City,
  State,
  Naics,
  ReceiptItem,
} from '@towncloud/thor-api';

import { CurrentTownService } from 'services';

import * as moment from 'moment';

@Component({
  templateUrl: './businessLicenseCertificate.html',
  styleUrls: ['./businessLicenseCertificate.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BusinessLicenseCertificateComponent {
  
  settings: any = {
    licenseNumberLabel: '',
    certificateTitle: '',
    licenseYearLabel: '',
    logoUrl: '',
    signatureUrl: ''
  };

  params: any;
  license: License;
  business: Business;
  town: City;
  city: City;
  receiptItem: ReceiptItem;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private businessesResource: BusinessesResource,
    private licensesResource: LicensesResource,
    private licenseTypesResource: LicenseTypesResource,
    private citiesResource: CitiesResource,
    private currentTownService: CurrentTownService,
    private receiptLineListResource: ReceiptLineListResource,
    private businessLicenseListResource: BusinessLicenseListResource,
    private licenseCertificateSettingsResource: LicenseCertificateSettingsResource
  ) {
    this.license = new License();
    this.license.type = new LicenseType();

    this.business = new Business();
    this.business.northAmericanIndustryClassificationSystemCode = new Naics();
    this.business.physicalAddress.state = new State();
    this.business.mailingAddress.state = new State();

    this.city = new City();

    this.receiptItem = new ReceiptItem();
  }

  ngOnInit() {
    setTimeout(() => {
      this.route.params.forEach((params: Params) => {
        this.params = params;

        this.getCity();
        this.getSettings();
        // this.getBusiness();
        this.getLicense(params['licenseId']).then((license: License) => {
          this.license = license;

          this.license.expirationDate = moment(
            this.license.expirationDate
          ).format('MM.DD.YYYY');
          this.license.dateIssued = moment(this.license.dateIssued).format(
            'MM.DD.YYYY'
          );

          this.getReceiptItem();

          this.getBusiness(this.license.businessId).then(
            (business: Business) => {
              this.business = business;
            }
          );
        });
      });
    });

    this.currentTownService.get().then(town => {
      this.town = town;
    });
  }

  getCity() {
    this.currentTownService.get().then(town => {
      var params = new ResourceParams({
        filters: [
          [
            {
              property: 'slug',
              operator: 'eq',
              value: town.slug
            }
          ]
        ],
        expand: ['address.state']
      });

      this.citiesResource.get(params).then(cities => {
        this.city = cities.items[0];
      });
    });
  }

  getSettings() {
    var params = new ResourceParams();

    this.licenseCertificateSettingsResource.get(params).then(results => {
      this.settings.licenseNumberLabel = results.licenseNumberLabel;
      this.settings.certificateTitle = results.certificateTitle;
      this.settings.licenseYearLabel = results.licenseYearLabel;

      if (results.logoUrl) {
        this.settings.logoUrl = results.logoUrl;
      }
      if (results.signatureUrl) {
        this.settings.signatureUrl = results.signatureUrl;
      }

    });
  }

  getBusiness(business) {
    return new Promise(resolve => {
      var params = new ResourceParams({
        filters: [
          [
            {
              property: 'id',
              operator: 'eq',
              value: business
            }
          ]
        ],
        expand: [
          'physicalAddress.state',
          'mailingAddress.state',
          'northAmericanIndustryClassificationSystemCode'
        ]
      });

      this.businessesResource.get(params).then(data => {
        resolve(data.items[0]);
      });
    });
  }

  getLicense(license) {
    return new Promise(resolve => {
      var params = new ResourceParams({
        filters: [
          [
            {
              property: 'id',
              operator: 'eq',
              value: license
            }
          ]
        ],
        expand: ['type']
      });

      this.businessLicenseListResource.get(params).then(data => {
        resolve(data.items[0]);
      });
    });
  }

  getReceiptItem() {
    var data = new ResourceParams({
      filters: [
        [
          {
            property: 'businessLicenseId',
            operator: 'eq',
            value: this.license.id
          },
          {
            property: 'receipt.status',
            operator: 'eq',
            value: 'Active'
          }
        ]
      ],
      expand: ['receipt']
    });

    this.receiptLineListResource.get(data).then(lines => {
      if (lines.items.length > 0) {
        this.receiptItem = lines.items[0];
      } else {
        console.error('There was an issue getting the receipt information');
      }
    });
  }

  navigateToReceipt() {
    this.router.navigate([
      this.town.slug,
      'receipts',
      this.receiptItem.receiptId
    ]);
  }

  print() {
    const head = document.getElementsByTagName('head')[0],
    html = this.elementRef.nativeElement.querySelector('#certificate').innerHTML;
    const mywindow = window.open('');

    mywindow.document.write(
      '<html><head>' +
        head.innerHTML +
        '</head><body onload="print();close();">' +
        html +
        '</body></html>'
    );
    // mywindow.document.write(`
    //   <html>
    //     <head>
    //       ${head.innerHTML}
    //       <style>
    //         .btn-group-center {
    //           display: none;
    //           visibility: hidden;
    //         }
    //       </style>
    //     </head>
    //     <body>
    //       ${html}
    //     </body>
    //   </html>
    // `);

    mywindow.document.close();
    mywindow.focus();

    // setTimeout(() => {
    //   mywindow.print();
    //   mywindow.close();
    // });
  }

}
