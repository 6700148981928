import { Component, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";

import {
  OpportunitiesResource,
  OpportunityTypesResource,
  ResourceParams,
  Opportunity,
  OpportunityType,
  Application,
  User
} from "@towncloud/thor-api";

import { CurrentUserResource, CityApplicationsResource } from "resources";
import { ResourceParams as StarkResourceParams } from "dataTypes";

@Component({
  selector: "tc-procurement-dashboard",
  templateUrl: "./procurement-dashboard.html",
  styleUrls: ["./procurement-dashboard.scss"]
})
export class ProcurementDashboardComponent {
  user: User = new User();
  activeCards: any[] = [];
  applications: Application[] = [];
  awardedOpportunities: any = { items: [], page: {} };
  canceledOpportunities: any = { items: [], page: {} };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currentUserResource: CurrentUserResource,
    private opportunityTypesResource: OpportunityTypesResource,
    private opportunitiesResource: OpportunitiesResource,
    private cityApplicationsResource: CityApplicationsResource
  ) {
    this.currentUserResource.get().then(user => {
      this.user = user;
    });
  }

  async ngOnInit() {
    const opportunityTypes = await this.getOpportunityTypes();

    for (let i = 0; i < opportunityTypes.length; i++) {
      const opportunities = await this.getOpportunityByTypeAndStatus(
        opportunityTypes[i].id,
        ["Active"]
      );
      this.activeCards.push({
        type: opportunityTypes[i],
        opportunities: opportunities.items,
        count: opportunities.page.totalCount
      });
    }

    let params = new ResourceParams({
      filters: [
        [
          {
            property: "status",
            operator: "eq",
            value: "Awarded"
          }
        ]
      ]
    });

    this.awardedOpportunities = await this.opportunitiesResource.get(params);

    params = new ResourceParams({
      filters: [
        [
          {
            property: "status",
            operator: "eq",
            value: "Canceled"
          }
        ]
      ]
    });
    this.canceledOpportunities = await this.opportunitiesResource.get(params);
  }

  async getOpportunityTypes() {
    const params = new ResourceParams({
      $all: true
    });

    const result = await this.opportunityTypesResource.get(params);

    return result.items;
  }

  async getOpportunityByTypeAndStatus(typeId, statuses) {
    const params = new ResourceParams({
      filters: []
    });

    statuses.forEach(status => {
      params.filters.push([
        {
          property: "opportunityTypeId",
          operator: "eq",
          value: typeId
        },
        {
          property: "status",
          operator: "eq",
          value: status
        }
      ]);
    });
    const result = await this.opportunitiesResource.get(params);

    return result;
  }

  async getCityApplications(slug) {
    const params = new StarkResourceParams({
      $header: {
        "x-city": slug
      }
    });

    const result = await this.cityApplicationsResource.get(params);
    return result;
  }

  navigateToOpportunitiesByType(typeId, status) {
    const navigationExtras: NavigationExtras = {
      queryParams: { typeid: typeId, status: status }
    };
    this.router.navigate(
      [this.route.snapshot.params["town"], "procurement", "opportunities"],
      navigationExtras
    );
  }

  navigateToOpportunityDetail(opportunityId) {
    this.router.navigate([
      this.route.snapshot.params["town"],
      "procurement",
      "opportunities",
      opportunityId
    ]);
  }
}
