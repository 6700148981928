import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";

import { appRouterModule } from "./app.routes";

import { ThorApiModule } from "@towncloud/thor-api";

import {
  DataService,
  MockDataService,
  ApiService,
  MenuService,
  CurrentUserService,
  CurrentTownService,
  FormService,
  CartService,
  NoticesService,
  DocumentsService,
  DocumentScanService,
  HammerApiService,
  HammerResourceService
} from "services";

import {
  CurrentUserResource,
  CityApplicationsResource,
  PasswordResource
} from "resources";

import { Filter } from "pipes/filter.pipe";
import { FieldMask } from "pipes/fieldMask.pipe";

import { AppComponent } from "./app.component";
import { TopNavigationComponent } from "./top-navigation/top-navigation.component";
import { ViewComponent } from "./components/view/view.component";

import { IconSpriteComponent } from "./components/icon/iconSprite.component";
import { IconComponent } from "./components/icon/icon.component";
import { FieldComponent } from "./components/field/field.component";
import { FieldCalendarComponent } from "./components/field/calendar/calendar.component";
import { AddressComponent } from "./components/address/address.component";
import { ContactDetailComponent } from "./components/contactDetail/contactDetail.component";
import { ModalComponent } from "./components/modal/modal.component";
import { BusinessDetailComponent } from "./components/businessDetail/businessDetail.component";
import { BusinessContactsComponent } from "./components/businessContacts/businessContacts.component";

import { RootComponent } from "./views/root/root.component";
import { NoTownComponent } from './views/noTown/noTown.component';
import { LoginComponent } from "./views/login/login.component";
import { RegistrationComponent } from "./views/login/registration/registration.component";
import { ForgotPasswordComponent } from "./views/login/forgotPassword/forgotPassword.component";
import { PasswordResetComponent } from "./views/login/passwordReset/passwordReset.component";
import { UserProfileComponent } from "./views/profile/profile.component";
import { UserProfileInformationComponent } from "./views/profile/information/profileInformation.component";
import { UserProfileSettingsComponent } from "./views/profile/settings/profileSettings.component";
import { UserProfileResetPasswordComponent } from "./views/profile/resetPassword/profileResetPassword.component";

import { MyBusinessComponent } from "./views/myBusiness/myBusiness.component";
import { BusinessManagementComponent } from "./views/myBusiness/management/businessManagement.component";
import { BusinessInformationComponent } from "./views/myBusiness/detail/information/businessInformation.component";
import { BusinessDocumentsComponent } from "./views/myBusiness/detail/documents/businessDocuments.component";
import { AddDocumentComponent } from "./views/myBusiness/detail/documents/create/addDocument.component";
import { BusinessDetailContactsComponent } from "./views/myBusiness/detail/contacts/businessDetailContacts.component";
import { BusinessLicensesComponent } from "./views/myBusiness/detail/licenses/businessLicenses.component";
import { BusinessLicenseCertificateComponent } from "./views/myBusiness/detail/licenses/certificate/businessLicenseCertificate.component";
import { CreateLicenseWizardComponent } from "./views/myBusiness/detail/licenses/create/createLicenseWizard.component";
import { ReceiptComponent } from "./views/receipt/receipt.component";

import { AddBusinessComponent } from "./views/addBusiness/addBusiness.component";
import { BusinessPinComponent } from "./views/businessPin/businessPin.component";
import { CreateBusinessWizardComponent } from "./views/myBusiness/create/createBusinessWizard.component";

import { CartComponent } from "./views/cart/cart.component";
import { CheckoutComponent } from "./views/checkout/checkout.component";

import { DashboardComponent } from "./views/dashboard/dashboard.component";

import { ProcurementTemplateComponent } from "./views/procurement/components/procurementTemplate/procurementTemplate.component";
import { ProcurementDashboardComponent } from "./views/procurement/procurement-dashboard/procurement-dashboard.component";
import { OpportunitiesComponent } from "./views/procurement/opportunities/opportunities.component";
import { OpportunityDetailComponent } from "./views/procurement/opportunityDetail/opportunityDetail.component";

import { environment } from "../environments/environment";
@NgModule({
  imports: [
    BrowserModule,
    appRouterModule,
    HttpClientModule,
    FormsModule,
    ThorApiModule.forRoot({ thorHostName: environment.thorHostName })
  ],
  declarations: [
    AppComponent,
    TopNavigationComponent,
    ViewComponent,

    IconSpriteComponent,
    IconComponent,
    FieldComponent,
    FieldCalendarComponent,
    AddressComponent,
    ContactDetailComponent,
    ModalComponent,
    BusinessDetailComponent,
    BusinessContactsComponent,
    BusinessDocumentsComponent,
    AddDocumentComponent,

    RootComponent,
    NoTownComponent,
    LoginComponent,
    ForgotPasswordComponent,
    RegistrationComponent,
    PasswordResetComponent,
    UserProfileComponent,
    UserProfileInformationComponent,
    UserProfileSettingsComponent,
    UserProfileResetPasswordComponent,
    MyBusinessComponent,
    BusinessManagementComponent,
    BusinessInformationComponent,
    BusinessDetailContactsComponent,
    BusinessLicensesComponent,
    CreateLicenseWizardComponent,
    BusinessLicenseCertificateComponent,
    ReceiptComponent,
    AddBusinessComponent,
    BusinessPinComponent,
    CreateBusinessWizardComponent,

    CartComponent,
    CheckoutComponent,

    DashboardComponent,

    ProcurementTemplateComponent,
    ProcurementDashboardComponent,
    OpportunitiesComponent,
    OpportunityDetailComponent,

    Filter,
    FieldMask
  ],
  providers: [
    DataService,
    MockDataService,
    ApiService,
    MenuService,
    CurrentUserService,
    CurrentTownService,
    FormService,
    CartService,
    NoticesService,
    DocumentsService,
    DocumentScanService,

    HammerApiService,
    HammerResourceService,

    CurrentUserResource,
    CityApplicationsResource,
    PasswordResource
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
