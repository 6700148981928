import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import {
  UsersResource,
  LicenseTypesResource,
  LicenseTypeFeesResource,
  LicensesResource,
  LicenseFeesResource,
  ItemsResource,

  ResourceParams,
  City,
  User,
  LicenseType,
  LicenseTypeFee,
  ReceiptItem,
  BusinessesResource,
  Business
} from '@towncloud/thor-api';

import {
  CurrentUserResource,
} from 'resources';

import {
  CurrentTownService,
  NoticesService,
  CartService
} from 'services';

@Component({
  templateUrl: './createLicenseWizard.html',
  styleUrls: ['./createLicenseWizard.scss']
})
export class CreateLicenseWizardComponent {

  params: any = {};
  town: City = new City();
  user: User = new User();
  business: Business = new Business();

  showLicenseTypes: boolean = true;
  showLicenseDetails: boolean = false;
  showLicenseReview: boolean = false;

  licenseTypes: LicenseType[] = [];
  selectedType: LicenseType;

  fees: LicenseTypeFee[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currentTownService: CurrentTownService,
    private currentUserResource: CurrentUserResource,
    private licenseTypesResource: LicenseTypesResource,
    private licenseTypeFeesResource: LicenseTypeFeesResource,
    private licensesResource: LicensesResource,
    private licenseFeesResource: LicenseFeesResource,
    private itemsResource: ItemsResource,
    private cartService: CartService,
    private businessesResource: BusinessesResource,
  ) {

  }

  async ngOnInit() {

    this.params = this.route.snapshot.params;

    this.currentTownService.get().then(town => {
      this.town = town;
    });

    this.currentUserResource.get().then(user => {
      this.user = user;
    });

    this.business = await this.getBusiness();
    this.getLicenseTypes();
  }

  async getBusiness() {
    const data = new ResourceParams({
      urlModifiers: {
        userId: this.user.id,
      },
      expand: [
        "ownershipType",
        "physicalAddress.state",
        "mailingAddress.state",
      ],
      filters: [
        [
          {
            property: "id",
            operator: "eq",
            value: this.route.snapshot.params.businessId,
          },
        ],
      ],
    });

    return (await this.businessesResource.get(data)).items[0];
  }

  async getLicenseTypes() {

    let params = new ResourceParams({
        filters: [
          [
            {
              property: "eligibleToCreateOnline",
              operator: "eq",
              value: true,
            },
          ],
        ],
    })

    this.licenseTypes = ( await this.licenseTypesResource.get(params) ).items;

  }

  async getFees(){
    let params = new ResourceParams({
      urlModifiers: {
        licenseTypeId: this.selectedType.id
      },
      $all: true,
      expand: ['fee']
    })

    this.fees = ( await this.licenseTypeFeesResource.get(params) ).items;
  }

  selectType(type){
    this.selectedType = type;

    this.getFees();
  }

  moveToTypes(){
    this.showLicenseTypes = true;
    this.showLicenseDetails = false;
    this.showLicenseReview = false;
  }

  moveToDetails(){
    this.showLicenseTypes = false;
    this.showLicenseDetails = true;
    this.showLicenseReview = false;
  }

  moveToReview(){
    this.showLicenseTypes = false;
    this.showLicenseDetails = false;
    this.showLicenseReview = true;
  }

  cancel(){
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  async createLicense(){
    var params = {
      urlModifiers: {
        businessId: this.params.businessId
      },
      typeId: this.selectedType.id,
      businessId: this.params.businessId
    };

    let license = await this.licensesResource.post(params)

    license.feeTotal = 0
    this.fees.forEach(item => {

      license.feeTotal += item.fee.amount * item.defaultQuantity;

    });

    await this.addFeesToLicense(license, this.fees);

    license.business = this.business;
    await this.addToCart(license);

    this.router.navigate([this.town.slug, 'cart']);
  }

  async addFeesToLicense(license, array) {

    for(let i = 0; this.fees.length > i; i++){
      let fee = this.fees[i];

      let params = {
        urlModifiers: {
          businessId: this.params.businessId,
          licenseId: license.id,
        },
        businessId: this.params.businessId,
        licenseId: license.id,
        feeId: fee.fee.id,
        quantity: fee.defaultQuantity
      }

      let result = await this.licenseFeesResource.post(params);

    }

  }

  async addToCart(license) {
    var params = new ResourceParams({
      expand: ['application'],
      filters: [
        [
          {
            property: 'application.name',
            operator: 'eq',
            value: 'Licenses'
          }
        ]
      ]
    });

    let items = (await this.itemsResource.get(params)).items;

    const item = items[0];
    const licenseFeeTotal = license.exemptFromFee ? 0 : license.feeTotal;

    const receiptItem = new ReceiptItem({
      itemId: item.id,
      item: item,
      chargeItem: item,
      quantity: 1,
      cost: licenseFeeTotal,
      businessLicenseId: license.id,
      businessLicense: license
    });

    this.cartService.add(receiptItem);
  }

  feeTotal(){
    let total = 0;
    this.fees.forEach( (fee) => {
      total += (fee.defaultQuantity * fee.fee.amount);
    })

    return total;
  }

}
