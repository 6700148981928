import { Injectable, EventEmitter } from "@angular/core";
import {
  ActivatedRoute,
  Params,
} from "@angular/router";

import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "@environments/environment";

import { ApiService, City } from "@towncloud/thor-api";

@Injectable()
export class CurrentTownService {
  private requestQueue: Array<any> = [];
  processingRequest: boolean = false;

  public ApiUrl: string = "/";
  public ServerWithApiUrl = environment.thorHostName + "/";

  route: ActivatedRoute;
  currentTownChange = new EventEmitter();
  town: City;
  slug: string;

  constructor(private http: HttpClient) {}

  setTown(town) {
    if (this.slug !== town) {
      ApiService.setCity(town);

      this.slug = town;

      this.get().then((town) => {
        this.emitChangeEvent(town);
      });
    }
  }

  setActiveRoute(route) {
    this.route = route;

    this.route.params.forEach((params: Params) => {
      if (params["town"] && this.slug !== params["town"]) {
        this.slug = params["town"];

        this.get().then((town) => {
          if (town) {
            this.town = town;
          } else {
            this.town = new City({
              slug: this.slug,
            });
          }

          ApiService.setCity(this.slug);
          this.emitChangeEvent(this.town);
        });
      }
    });
  }

  emitChangeEvent(town) {
    this.currentTownChange.emit(town);
  }

  getChangeEmitter() {
    return this.currentTownChange;
  }

  get(): Promise<any> {
    var promise = new Promise((resolve, reject) => {
      this.requestQueue.push({
        resolve: resolve,
        reject: reject,
      });
    });

    this.getCity();

    return promise;
  }

  getCity() {
    if (this.processingRequest == false) {
      this.processingRequest = true;

      if (this.town == undefined) {
        this.queryCity().then((res: any) => {
          var data = res;

          if (data.page.totalCount > 0) {
            this.town = data.items[0];
          } else {
            this.town = undefined;
          }

          this.requestQueue[0].resolve(this.town);

          this.requestQueue.shift();
          this.getCity();
        });
      } else {
        this.requestQueue[0].resolve(this.town);

        this.requestQueue.shift();
        this.getCity();
      }
    } else {
      this.processingRequest = false;
    }
  }

  queryCity() {
    var accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
    var headers = new HttpHeaders();

    if (accessToken) {
      headers.append("X-Token", accessToken.token);
    }
    var options = {
      headers: headers,
    };

    return this.http
      .get(
        environment.thorHostName +
          "/cities?expand=address.state&filter=(slug:eq:" +
          this.slug +
          ")",
        options
      )
      .toPromise();
  }
}
