import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class Filter implements PipeTransform {
  transform(fees: any[], test) {

    return fees.filter(fee => {

      let val = eval('fee.'+test.property);

      if(test.value.constructor.name == 'Boolean'){
        return ( !!val == test.value);
      }else{
        return (val == test.value);
      }
      
    });

  }
}