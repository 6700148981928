"use strict";

const config = [
	{
		issuer: "Visa",
		startsWith: ["4"],
		patterns: [
			{ length: 13, mask: 4 },
			{ length: 14, mask: 4 },
			{ length: 15, mask: 4 },
			{ length: 16, mask: 4 },
			{ length: 17, mask: 4 },
			{ length: 18, mask: 4 },
			{ length: 19, mask: 4 },
		],
		cvvLength: [3],
	},
	{
		issuer: "American Express",
		startsWith: ["37", "34"],
		patterns: [{ length: 15, mask: [4, 6, 5] }],
		cvvLength: [3, 4],
	},
	{
		issuer: "China Union Pay",
		startsWith: ["37", "34"],
		patterns: [
			{ length: 16, mask: 4 },
			{ length: 17, mask: 4 },
			{ length: 18, mask: 4 },
			{ length: 19, mask: [6, 13] },
		],
		cvvLength: [3],
	},
	{
		issuer: "MasterCard",
		startsWith: ["51-55", "222100-272099"],
		patterns: [{ length: 16, mask: 4 }],
		cvvLength: [3],
	},
	{
		issuer: "Maestro",
		startsWith: ["500000-509999", "560000-589999", "600000-699999"],
		patterns: [
			{ length: 12, mask: 4 },
			{ length: 13, mask: [4, 4, 5] },
			{ length: 14, mask: [4, 5, 5] },
			{ length: 15, mask: [4, 6, 5] },
			{ length: 16, mask: 4 },
			{ length: 17, mask: [4, 4, 4, 5] },
			{ length: 18, mask: [4, 4, 5, 5] },
			{ length: 19, mask: [4, 4, 4, 4, 3] },
		],
		cvvLength: [3],
	},
	{
		issuer: "Diners Club - Carte Blanche",
		startsWith: ["300-305"],
		patterns: [{ length: 14, mask: [4, 6, 4] }],
		cvvLength: [3],
	},
	{
		issuer: "Diners Club - International",
		startsWith: ["309", "36", "38-39"],
		patterns: [{ length: 14, mask: [4, 6, 4] }],
		cvvLength: [3],
	},
	{
		issuer: "Diners Club - USA & Canada",
		startsWith: ["54", "55"],
		patterns: [{ length: 16, mask: 4 }],
		cvvLength: [3],
	},
	{
		issuer: "Discover",
		startsWith: ["6011", "622126-622925", "644-649", "65"],
		patterns: [{ length: 16, mask: 4 }],
		cvvLength: [3],
	},
	{
		issuer: "JCB",
		startsWith: ["3528-6589"],
		patterns: [{ length: 16, mask: 4 }],
		cvvLength: [3],
	},
	{
		issuer: "UATP",
		startsWith: ["1"],
		patterns: [{ length: 15, mask: [4, 5, 6] }],
		cvvLength: [3],
	},
	{
		issuer: "Dankort",
		startsWith: ["5019"],
		patterns: [{ length: 16, mask: 4 }],
		cvvLength: [3],
	},
	{
		issuer: "InstaPayment",
		startsWith: ["637", "638", "639"],
		patterns: [{ length: 16, mask: 4 }],
		cvvLength: [3],
	},
	{
		issuer: "InterPayment",
		startsWith: ["636"],
		patterns: [
			{ length: 16, mask: 4 },
			{ length: 17, mask: 4 },
			{ length: 18, mask: 4 },
			{ length: 19, mask: 4 },
		],
		cvvLength: [3],
	},
];

const getCreditCard = (number) => {
	for (let i = 0; i < config.length; i++) {
		const startsWith = config[i].startsWith;
		for (let j = 0; j < startsWith.length; j++) {
			if (startsWith[j].indexOf("-") !== -1) {
				if (number) {
					const range = startsWith[j].split("-");
					var n = number.substr(0, range[0].length);

					if (n && n >= parseInt(range[0]) && n <= parseInt(range[1])) {
						return config[i];
					}
				}
			} else {
				if (number && number.indexOf(startsWith[j]) === 0) {
					return config[i];
				}
			}
		}
	}

	return false;
};

const getPatter = (number) => {
	const cc = getCreditCard(number);
	const pattern = { mask: 4 };

	if (!cc) return pattern;

	const patterns = cc.patterns;
	for (let j = 0; j < patterns.length; j++) {
		if (number.length <= patterns[j].length) {
			return patterns[j];
		}
	}

	return { mask: 4 };
};

export class CreditCard {
	number: string;
	cc: any;
	pattern: any;

	constructor(value) {
		this.number = value;
		this.cc = getCreditCard(value);
	}

	get() {
		return this.cc;
	}

	format() {
		if (!this.number) return;

		let ccNumber = this.number.replace(/ /g, "");
		const pattern = getPatter(ccNumber);

		if (!pattern) return this.number;

		const mask = pattern.mask;

		var ret = [];

		if (typeof mask === "number") {
			for (let i = 0; i < ccNumber.length; i += mask) {
				ret.push(ccNumber.substr(i, mask));
			}
		} else if (Array.isArray(mask)) {
			let maskPosition = 0;

			for (let i = 0; i < mask.length; i++) {
				const val = ccNumber.substr(maskPosition, mask[i]);
				if (val != "") {
					ret.push(val);
				}
				maskPosition += mask[i];
			}
		}

		return ret.join(" ");
	}
}
