import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';

import { CurrentTownService, NoticesService, DocumentsService } from 'services';

import {
  BusinessesResource,
	AttachmentsResource,
	BusinessAttachmentsResource,
	DocumentTypesResource,
  User,
  Business,
	ResourceParams,
  BusinessAttachment,
} from '@towncloud/thor-api';

import { CurrentUserResource } from 'resources';

@Component({
  templateUrl: './businessDocuments.html',
  styleUrls: ['./businessDocuments.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BusinessDocumentsComponent {
  user: User = new User();
  business: Business = new Business();
  documents: Array<BusinessAttachment> = [];
  // documents: Array<any> = [];
  documentTypes: Array<DocumentType> = [];
  noticeSubscription: Subscription;
  documentSubscription: Subscription;
  notices: any = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currentUserResource: CurrentUserResource,
    private businessesResource: BusinessesResource,
    private attachmentsResource: AttachmentsResource,
    private businessAttachmentsResource: BusinessAttachmentsResource,
    private documentTypesResource: DocumentTypesResource,
    private currentTownService: CurrentTownService,
    private noticesService: NoticesService,
    private documentsService: DocumentsService,
  ) {
  }

  async ngOnInit() {
    let params = this.route.snapshot.params;

    this.user = await this.currentUserResource.get();

    this.business = await this.getBusiness(params['businessId']);

    this.documentTypes = await this.getDocumentTypes();

    // this.documentSubscription = this.documentsService
    // .getChangeEmitter()
    // .subscribe((documents) => {
    //   this.documents = documents;
    //   // this is docs for all user businesses, html will limit by businessId
    // });

    if (this.documents.length === 0) {
      this.documents = await this.documentsService.get();
    }

    // this.noticeSubscription = this.noticesService
    // .getChangeEmitter()
    // .subscribe((notices) => {
    //   this.notices = notices;
    // });

    if (this.notices.length === 0) {
      this.notices = await this.noticesService.get();
    }

  }

  ngOnDestroy() {
    // if (this.noticeSubscription) {
    //   this.noticeSubscription.unsubscribe();
    // }
  }

  async getBusiness(businessId) {
    const data = new ResourceParams({
      urlModifiers: {
        userId: this.user.id,
      },
      filters: [
        [
          {
            property: 'id',
            operator: 'eq',
            value: businessId,
          },
        ],
      ],
    });

    return (await this.businessesResource.get(data)).items[0];
  }

  async getDocumentTypes() {
		const params = new ResourceParams({
			$all: true,
		});

		return (await this.documentTypesResource.get(params)).items;
	}
 

  setDocuments() {
    this.documents = this.documentsService.get();
  }

  navigateToAddDocument() {
    this.router.navigate(['add'], { relativeTo: this.route });
  }

  navigateToBusiness() {
    this.currentTownService.get().then((town) => {
      this.router.navigate([town.slug, 'mybusiness']);
    });
  }

}
