import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { environment } from '@environments/environment';

@Injectable()
export class DocumentScanService {
  private apiUrl = `https://api.cloudmersive.com/virus/scan/file/advanced`;

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'allowExecutables': '',
      'allowInvalidFiles': '',
      'allowScripts': '',
      'allowPasswordProtectedFiles': '',
      'allowMacros': '',
      'allowXmlExternalEntities': '',
      'allowInsecureDeserialization': '',
      'allowHtml': '',
      'restrictFileTypes': '',
      'Content-Type': 'multipart/form-data',
      'Apikey': `${environment.virusScanApiKey}`
    })
  };

  public scanDocument(file) {

    const formData = new FormData();
    formData.append('inputFile', file);

    return this.http.post(this.apiUrl, formData, this.httpOptions)
      .toPromise()
        .then( (res: any) => {
          // console.log(res);
          // console.log('====clean result ? ' + res.CleanResult);
          return res.CleanResult;
        })
        .catch((e) => {
          console.log(e);
          this.handleError(e);
        });


  }

  private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// Client error
			console.error('An error occurred:', error.error.message);
		} else {
			// backend error
			console.error(
				`Backend returned code ${error.status}, ` + `body was: ${error.error}`
			);
		}
		// observable error message
		return throwError(() => 'Something bad happened; please try again later.');
	}
}
