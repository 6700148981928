import {
  Component,
  ViewEncapsulation,
  ViewChild,
  ViewChildren,
} from "@angular/core";

import {
  BusinessesResource,
  BusinessContactsResource,
  UsersResource,
  ContactsResource,
  OwnershipTypesResource,
  Business,
  City,
  Contact,
  User,
} from "@towncloud/thor-api";

import { CurrentUserResource } from "resources";

import { CurrentTownService, DocumentsService } from "services";

import { BusinessContactsComponent } from "businessContacts/businessContacts.component";

@Component({
  templateUrl: "./createBusinessWizard.html",
  styleUrls: ["./createBusinessWizard.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateBusinessWizardComponent {
  @ViewChildren("pin")
  pin;

  @ViewChild(BusinessContactsComponent)
  businessContactsComponent: BusinessContactsComponent;

  town: City = new City();
  user: User = new User();

  business: Business = new Business();
  contacts: Array<Contact> = [];

  showBusinessDetail: boolean = true;
  showContacts: boolean = false;
  showWelcome: boolean = false;

  physicalAddress: any = {};
  mailingAddress: any = {};

  constructor(
    private businessesResource: BusinessesResource,
    private currentTownService: CurrentTownService,
    private currentUserResource: CurrentUserResource,
    private documentsService: DocumentsService
  ) {}

  ngOnInit() {
    this.currentTownService.get().then((town) => {
      this.town = town;
    });

    this.currentUserResource.get().then((user) => {
      this.user = user;
    });
  }

  moveToContacts() {
    this.showBusinessDetail = false;
    this.showContacts = true;
  }

  async save() {
    this.business.id = (await this.createBusiness()).id;

    const data = {
      urlModifiers: {
        userId: this.user.id,
      },
      id: this.business.id,
    };

    await this.businessesResource.create(data);

    await this.saveContacts();

    this.showContacts = false;
    this.showWelcome = true;

    this.documentsService.updateDocuments();
  }

  async createBusiness() {
    const data = {
      name: this.business.name,
      doingBusinessAs: this.business.doingBusinessAs,
      ownershipTypeId: this.business.ownershipTypeId,
      stateRegistrationNumber: this.business.stateRegistrationNumber,
      taxIdetificationNumber: this.business.taxIdetificationNumber,
      phone: this.business.phone,
      fax: this.business.fax,
      website: this.business.website,
      physicalAddress: {
        address1: this.business.physicalAddress.address1,
        address2: this.business.physicalAddress.address2,
        city: this.business.physicalAddress.city,
        stateId: this.business.physicalAddress.stateId,
        zipCode: this.business.physicalAddress.zipCode,
        countryId: this.business.physicalAddress.countryId,
      },
      mailingAddress: {
        address1: this.business.mailingAddress.address1,
        address2: this.business.mailingAddress.address2,
        city: this.business.mailingAddress.city,
        stateId: this.business.mailingAddress.stateId,
        zipCode: this.business.mailingAddress.zipCode,
        countryId: this.business.mailingAddress.countryId,
      },
    };

    return await this.businessesResource.post(data);
  }

  async saveContacts() {
    await this.businessContactsComponent.applyChanges();
  }
}
