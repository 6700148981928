import { Injectable } from '@angular/core';
import { HammerApiService } from './hammerApi.service';

import { ResourceParams, DataType } from 'dataTypes';

@Injectable()
export class HammerResourceService {

	url: string;

	constructor(
		private hammerApiService: HammerApiService
	){}

	post(value){

		return this.hammerApiService.post(this.url, value).then( response => {
			return Promise.resolve(response);
		}, (e) => {
			return Promise.reject(e);
		})
	}

	create(value){

		return this.post(value)
	}

	get(params = new ResourceParams){

		return this.hammerApiService.get(this.url, params).then( response => {

			if(response.items != undefined){
				// response = new ResourceGetResponse(response);
			}			

			if(params.$bold == true){

				response._boldResults(params.filters);
			}
			return Promise.resolve(response);
		}, (e) => {
			return Promise.reject(e);
		})
	}

	select(id){
		var params = new ResourceParams(
			{ 
				filters: [
					[
						{
							property: 'id',
							operator: 'eq',
							value: id
						}
					]
				] 
			}
		);

		return this.hammerApiService.get(this.url, params).then( response => {

			// response = new ResourceGetResponse(response);

			if(response.items.length > 0){
				return Promise.resolve(response.items[0]);
			}

			return Promise.resolve(response);
		}, (e) => {
			return Promise.reject(e);
		})
	}

	put(value: any = {}){
		let url = this.url;
		if(value.urlModifiers){
			if(value.urlModifiers.id){
				url += '/' + value.urlModifiers.id;
			}
		}
		return this.hammerApiService.update(url, value).then( response => {
			return Promise.resolve(response);
		}, (e) => {
			return Promise.reject(e);
		})
	}

	update(value = {}){
		return this.put(value);
	}

	delete(value = {}){
		// return this.hammerApiService.delete(this.url, value).then( response => {
		return this.hammerApiService.delete(this.url).then( response => {
			return Promise.resolve(response);
		}, (e) => {
			return Promise.reject(e);
		})
	}
}
