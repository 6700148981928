import {
  Component,
  ViewEncapsulation,
  Output,
  EventEmitter
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DataService, CurrentTownService, FormService } from 'services';

import {
  AccessTokenResource,
  UsersResource,

  City
} from '@towncloud/thor-api';

import {
  CurrentUserResource
} from 'resources';

import { Field  } from 'dataTypes';

@Component({
  selector: "registration",
  templateUrl: './registration.html',
  encapsulation: ViewEncapsulation.None
})
export class RegistrationComponent {

  @Output() registered: EventEmitter<any> = new EventEmitter();

  public town: City = new City();

  public registerFields: any = {};
  public registerFailed: boolean = false;

  processing = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accessTokenResource: AccessTokenResource,
    private usersResource: UsersResource,
    private currentUser: CurrentUserResource,
    private currentTownService: CurrentTownService,
    private formService: FormService
  ) {
    
    this.registerFields.firstName = new Field({
      type: 'input',
      label: 'First Name',
      placeholder: 'Enter your first name',
      id: 'register-first-name',
      required: true
    });

    this.registerFields.lastName = new Field({
      type: 'input',
      label: 'Last Name',
      placeholder: 'Enter your last name',
      id: 'register-last-name',
      required: true
    });

    this.registerFields.email = new Field({
      type: 'input',
      label: 'Email',
      placeholder: 'Enter your email address',
      id: 'register-email',
      required: true
    });

    this.registerFields.password = new Field({
      type: 'password',
      label: 'Password',
      placeholder: 'Minimum 8 characters',
      id: 'register-password',
      required: true
    });

    this.registerFields.confirmPassword = new Field({
      type: 'password',
      label: 'Confirm password',
      placeholder: 'Re-enter your password',
      id: 'register-password-confirm',
      required: true
    });

  }

  ngOnInit() {
    this.currentTownService.get().then(town => {
      this.town = town;
    });
  }

  updateRegisterField(e: any) {
    this.registerFailed = false;
  }

  registrationFormIsValid() {
    if(this.processing) return false;

    if (this.formService.isRequiredFieldsValid(this.registerFields)) {
      if (
        this.registerFields.password.value.length >= 8 &&
        this.registerFields.password.value ===
          this.registerFields.confirmPassword.value
      ) {
        return true;
      }
    }

    return false;
  }

  async register() {
    this.processing = true;
    let data = {
      firstName: this.registerFields.firstName.value,
      lastName: this.registerFields.lastName.value,
      email: this.registerFields.email.value,
      password: this.registerFields.password.value,
      passwordResetRequired: false
    };

    try {
      const user = await this.usersResource.create(data);
      user.password = this.registerFields.password.value;

      this.registered.emit(user);
    }catch(e) {
      if(e.status == 422){
        this.registerFailed = true;
      }

      this.processing = false;
    }
  }

}
