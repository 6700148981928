import {
  Component,
  ViewEncapsulation,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { DataService } from 'services';

import { Field } from 'dataTypes';

import {
  BusinessesResource,
  BusinessContactsResource,
  UsersResource,
  ContactsResource,
  OwnershipTypesResource,
  BusinessLicensesResource,
  ResourceParams,
  Business,
  City,
  Contact,
  User,
  OwnershipType,
} from '@towncloud/thor-api';

import { CurrentUserResource } from 'resources';

import { CurrentTownService, DocumentsService } from 'services';

import { BusinessContactsComponent } from 'businessContacts/businessContacts.component';

@Component({
  templateUrl: './businessPin.html',
  styleUrls: ['./businessPin.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BusinessPinComponent {
  @ViewChildren('pin') pin;

  @ViewChild(BusinessContactsComponent)
  businessContactsComponent: BusinessContactsComponent;

  fields: any = {};
  town: City = new City();
  user: User = new User();

  business: Business = new Business();
  contacts: Array<Contact> = [];

  showPin: boolean = true;
  showBusiness: boolean = false;
  showWrongBusiness: boolean = false;
  showBusinessDetail: boolean = false;
  showContacts: boolean = false;
  showWelcome: boolean = false;
  hasLicenses: boolean = false;

  physicalAddress: any = {};
  mailingAddress: any = {};

  pinError: string;
  businessValid: boolean;

  constructor(
    private businessesResource: BusinessesResource,
    private currentTownService: CurrentTownService,
    private businessContactsResource: BusinessContactsResource,
    private currentUserResource: CurrentUserResource,
    private contactsResource: ContactsResource,
    private businessLicensesResource: BusinessLicensesResource,
    private documentsService: DocumentsService
  ) {
    this.fields[0] = new Field({
      type: 'input',
      id: 1,
      max: 1,
    });

    this.fields[1] = new Field({
      type: 'input',
      id: 2,
      max: 1,
    });

    this.fields[2] = new Field({
      type: 'input',
      id: 3,
      max: 1,
    });

    this.fields[3] = new Field({
      type: 'input',
      id: 4,
      max: 1,
    });

    this.fields[4] = new Field({
      type: 'input',
      id: 5,
      max: 1,
    });

    this.fields[5] = new Field({
      type: 'input',
      id: 6,
      max: 1,
    });
  }

  ngOnInit() {
    this.currentTownService.get().then((town) => {
      this.town = town;
    });

    this.currentUserResource.get().then((user) => {
      this.user = user;
    });
  }

  pinChanged(num) {
    this.pinError = undefined;

    var hasValue = false;

    this.pin._results.forEach((item) => {
      if (item.settings.id == num) {
        if (item.settings.value != undefined) {
          hasValue = true;
        }
      }

      if (item.settings.id == num + 1 && hasValue) {
        item.setFocus();
      }
    });
  }

  async checkBusiness() {
    var params = new ResourceParams({
      filters: [
        [
          {
            property: 'pin',
            operator: 'eq',
            value:
              this.fields[0].value +
              this.fields[1].value +
              this.fields[2].value +
              this.fields[3].value +
              this.fields[4].value +
              this.fields[5].value,
          },
        ],
      ],
      expand: [
        'physicalAddress.state',
        'physicalAddress.country',
        'mailingAddress.state',
        'mailingAddress.country',
        'ownershipType',
      ],
    });

    this.businessesResource.get(params).then((businesses) => {
      if (businesses.items.length > 0) {
        var params = new ResourceParams({
          urlModifiers: {
            userId: this.user.id,
          },
          filters: [
            [
              {
                property: 'id',
                operator: 'eq',
                value: businesses.items[0].id,
              },
            ],
          ],
          expand: [
            'physicalAddress.state',
            'physicalAddress.country',
            'mailingAddress.state',
            'mailingAddress.country',
            'ownershipType',
          ],
        });

        this.businessesResource.get(params).then((results) => {
          if (results.items.length === 0) {
            this.business = businesses.items[0];
            this.physicalAddress = this.business.physicalAddress;
            this.mailingAddress = this.business.mailingAddress;
            this.showPin = undefined;
            this.showBusiness = true;
          } else {
            this.pinError =
              'You have already successfully linked your business to your user profile. Please Click My Businesses to find and work with your business.';
          }
        });
      } else {
        this.pinError = 'Sorry this pin does not work. Please try again.';
      }
    });
  }

  showWrongBusinessMessage() {
    this.showBusiness = false;
    this.showWrongBusiness = true;
  }

  moveToContacts() {
    var data = new ResourceParams({
      urlModifiers: {
        businessId: this.business.id,
      },
      expand: ['contact', 'contact.address.state', 'contact.address.country'],
    });

    this.businessContactsResource.get(data).then((contacts) => {
      this.contacts = contacts.items;

      this.showBusinessDetail = false;
      this.showContacts = true;
    });
  }

  save() {
    var data = {
      urlModifiers: {
        userId: this.user.id,
      },
      id: this.business.id,
    };

    this.businessesResource.create(data).then((user) => {
      this.saveBusiness().then(() => {
        this.saveContacts().then(() => {
          this.showContacts = false;

          this.moveToWelcom();
          this.documentsService.updateDocuments();
          // update documents will trigger update notices
        });
      });
    });
  }

  saveBusiness() {
    return new Promise((resolve, reject) => {
      var data = {
        urlModifiers: {
          id: this.business.id,
        },
        id: this.business.id,
        name: this.business.name,
        doingBusinessAs: this.business.doingBusinessAs,
        ownershipTypeId: this.business.ownershipTypeId,
        stateRegistrationNumber: this.business.stateRegistrationNumber,
        taxIdetificationNumber: this.business.taxIdetificationNumber,
        phone: this.business.phone,
        fax: this.business.fax,
        website: this.business.website,
        physicalAddress: {
          address1: this.business.physicalAddress.address1,
          address2: this.business.physicalAddress.address2,
          city: this.business.physicalAddress.city,
          stateId: this.business.physicalAddress.state.id,
          zipCode: this.business.physicalAddress.zipCode,
          countryId: this.business.physicalAddress.country.id,
        },
        mailingAddress: {
          address1: this.business.mailingAddress.address1,
          address2: this.business.mailingAddress.address2,
          city: this.business.mailingAddress.city,
          stateId: this.business.mailingAddress.state
            ? this.business.mailingAddress.state.id
            : undefined,
          zipCode: this.business.mailingAddress.zipCode,
          countryId: this.business.mailingAddress.country
            ? this.business.mailingAddress.country.id
            : undefined,
        },
      };

      this.businessesResource.update(data).then((business) => {
        resolve(business);
      });
    });
  }

  async saveContacts() {
    await this.businessContactsComponent.applyChanges();

    // return new Promise((resolve, reject) => {
    //   var array = this.contacts.slice(0);

    //   this.loopContacts(array).then(() => {
    //     resolve(this.contacts);
    //   });
    // });
  }

  loopContacts(array) {
    return new Promise((resolve, reject) => {
      if (array.length === 0) {
        resolve();
      }
      if (array[0].id) {
        this.saveContact(array[0].contact).then((contact) => {
          array.shift();

          if (array.length === 0) {
            resolve();
          } else {
            this.loopContacts(array).then(() => {
              resolve();
            });
          }
        });
      }
    });
  }

  saveContact(contact) {
    return new Promise((resolve, reject) => {
      var data = {
        urlModifiers: {
          id: contact.id,
        },
        id: contact.id,
        typeId: contact.typeId,
        title: contact.title,
        name: contact.name,
        preferredName: contact.preferredName,
        address: {
          address1: contact.address.address1,
          address2: contact.address.address2,
          city: contact.address.city,
          stateId: contact.address.stateId,
          stateName: contact.address.stateName,
          zipCode: contact.address.zipCode,
          countryId: contact.address.countryId,
        },
        homePhone: contact.homePhone,
        cellPhone: contact.cellPhone,
        workPhone: contact.workPhone,
        fax: contact.fax,
        email: contact.email,
      };

      this.contactsResource.update(data).then((contact) => {
        resolve(contact);
      });
    });
  }

  async moveToWelcom() {
    this.showWelcome = true;

    let params = new ResourceParams({
      urlModifiers: {
        businessId: this.business.id,
      },
    });

    let result = (await this.businessLicensesResource.get(params)).items;

    if (result.length > 0) {
      this.hasLicenses = true;
    }
  }
}
