import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import {
  CartService,
  FormService,
  CurrentTownService,
  DocumentsService,
} from 'services';

import {
  BusinessesResource,
  ReceiptsResource,
  ReceiptItemsResource,
  ReceiptTendersResource,
  TendersResource,
  TenderFeesResource,
  BatchesResource,
  PaymentsResource,
  PaymentSourcesResource,
  ResourceParams,
  User,
  Business,
  Batch,
  Fee,
  Tender,
  PaymentSource,
} from '@towncloud/thor-api';

import { CurrentUserResource } from 'resources';

import { Field } from 'dataTypes';

import { CreditCard } from 'classes/creditCard';

import * as uuid from 'uuid/v1';
import * as moment from 'moment';

@Component({
  templateUrl: './checkout.html',
  styleUrls: ['./checkout.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutComponent {
  user: User = new User();
  business: Business = new Business();
  fields: any = {};

  cart: Array<any> = [];
  cartTotal: number = 0;
  checkoutError: any;
  canCheckout: boolean = true;
  fees: Array<Fee> = [];
  paymentSource: PaymentSource = new PaymentSource();
  referenceNumber = '';

  tender: Tender = new Tender();

  constructor(
    private router: Router,
    private currentUserResource: CurrentUserResource,
    private businessesResource: BusinessesResource,
    private cartService: CartService,
    private formService: FormService,
    private receiptsResource: ReceiptsResource,
    private receiptItemsResource: ReceiptItemsResource,
    private receiptTendersResource: ReceiptTendersResource,
    private tendersResource: TendersResource,
    private tenderFeesResource: TenderFeesResource,
    private batchesResource: BatchesResource,
    private paymentSourcesResource: PaymentSourcesResource,
    private paymentsResource: PaymentsResource,
    private currentTownService: CurrentTownService,
    private documentsService: DocumentsService
  ) {
    this.fields.firstName = new Field({
      type: 'input',
      label: 'First Name',
      placeholder: 'Enter card holder first name',
      required: true,
    });

    this.fields.lastName = new Field({
      type: 'input',
      label: 'Last Name',
      placeholder: 'Enter card holder last name',
    });

    this.fields.cardNumber = new Field({
      type: 'input',
      label: 'Card number',
      placeholder: 'Enter card number',
      mask: 'creditCard',
      required: true,
    });

    this.fields.expirationMonth = new Field({
      type: 'input',
      label: 'Expire date',
      placeholder: 'MM',
      max: 2,
      required: true,
    });

    this.fields.expirationYear = new Field({
      type: 'input',
      label: 'Year',
      placeholder: 'YYYY',
      max: 4,
      required: true,
    });

    this.fields.cvv = new Field({
      type: 'input',
      label: 'CVV',
      placeholder: 'CVV',
      required: true,
    });

    this.fields.address = {};

    this.fields.address.firstName = new Field({
      type: 'input',
      label: 'First name',
      placeholder: 'First name',
    });

    this.fields.address.lastName = new Field({
      type: 'input',
      label: 'Last name',
      placeholder: 'Last name',
    });

    this.fields.address.streetAddress = new Field({
      type: 'input',
      label: 'Street Address',
      placeholder: 'Street address',
    });

    this.fields.address.city = new Field({
      type: 'input',
      label: 'City',
      placeholder: 'Enter city',
    });

    this.fields.address.state = new Field({
      type: 'input',
      label: 'State/Region',
      placeholder: 'State/Region',
    });

    this.fields.address.zip = new Field({
      type: 'input',
      label: 'Postal code',
      placeholder: 'Postal code',
    });
  }

  ngOnInit() {
    this.currentUserResource.get().then((user) => {
      this.user = user;

      this.getBusiness().then((business: Business) => {
        this.business = business;
      });
    });

    this.getPaymentSource().then((source: PaymentSource) => {
      this.paymentSource= source;
    });

    this.cart = this.cartService.get();

    this.cart.forEach((item) => {
      this.cartTotal += parseFloat(item.cost);
    });

    // this.getFees().then( (fees: Array<Fee>)=> {
    //   this.fees = fees;

    //   this.fees.forEach((fee:Fee) => {
    //     this.cartTotal += parseFloat(String(fee.amount));
    //   })
    // })

    let params = new ResourceParams({
      filters: [
        [
          {
            property: 'isMerchantCredit',
            operator: 'eq',
            value: true,
          },
        ],
      ],
    });
    console.log(this.cartTotal);
    if (this.cartTotal > 0) {
      this.tendersResource.get(params).then((tenders) => {
        this.tender = tenders.items[0];

        this.getFees().then((fees: Array<Fee>) => {
          this.fees = fees;

          this.fees.forEach((fee: Fee) => {
            this.cartTotal += parseFloat(String(fee.amount));
          });
        });
      });
    }
  }

  getBusiness() {
    return new Promise((resolve, reject) => {
      const data = new ResourceParams({
        urlModifiers: {
          userId: this.user.id,
        },
        expand: ['ownershipType', 'physicalAddress.state'],
      });

      this.businessesResource.get(data).then((businesses) => {
        if (businesses.items.length > 0) {
          resolve(businesses.items[0]);
        } else {
          resolve(undefined);
        }
      });
    });
  }

  getFees() {
    return new Promise((resolve) => {
      // var params = new ResourceParams({
      //   $all: true,
      //   expand: ['application'],
      //   filters: [
      //     [
      //       {
      //         property: 'application.name',
      //         operator: 'eq',
      //         value: 'Payments'
      //       }
      //     ]
      //   ]
      // })

      // this.feesResource.get( params ).then( (fees) => {
      //   resolve(fees.items);
      // })
      //
      let params = new ResourceParams({
        $all: true,
        urlModifiers: {
          tenderId: this.tender.id,
        },
      });

      this.tenderFeesResource.get(params).then((fees) => {
        resolve(fees.items);
      });
    });
  }

  getPaymentSource() {
    return new Promise((resolve, reject) => {
      let params = new ResourceParams({
        filters: [
          [
            {
              property: 'useForOnlineBatches',
              operator: 'eq',
              value: true
            }
          ],
        ]
      });

      this.paymentSourcesResource.get(params).then((sources) => {
        if (sources.items.length > 0) {
          resolve(sources.items[0]);
        } else {
          resolve(undefined);
        }
      });
    });
  }


  isExpirationDateValid() {
		if (
			this.fields.expirationMonth.value.length !== 2 ||
			this.fields.expirationYear.value.length !== 4
		) {
			return false;
		}

		const date = moment(
			`${this.fields.expirationMonth.value}/1/${this.fields.expirationYear.value}`
		);
		date.add(1, 'M');
		const today = moment();
		return today.isBefore(date);
  }
  
  isCCValid(){
    const cc = new CreditCard(this.fields.cardNumber.value).get();
    
    if(cc){
      for(let i = 0; i < cc.patterns.length; i++){
        if(this.fields.cardNumber.value.replace(/ /g,'').length === cc.patterns[i].length){
          return true;
        }
      }
    }

    return false;
  }

	isCvvValid() {
		const cc = new CreditCard(this.fields.cardNumber.value).get();
		const cvvLength = cc.cvvLength;

		if (cvvLength) {
			for (let i = 0; i <= cvvLength.length; i++) {
				if (this.fields.cvv.value.length === cvvLength[i]) return true;
			}

			return false;
		} else if (this.fields.cvv.value.length === 3) {
			return true;
		}

		return false;
	}

  isFormValid() {
    if (this.cartTotal === 0 && this.cart.length > 0) {
      return true;
    }

    if (
			this.formService.isRequiredFieldsValid(this.fields) &&
      this.formService.isRequiredFieldsValid(this.fields.address) &&
      this.isCCValid() &&
			this.isExpirationDateValid() &&
			this.isCvvValid()
		) {
			return true;
		}

		return false;
  }

  showErrorMessage(){
    
    if(this.formService.isRequiredFieldsValid(this.fields)){
      if(!this.isCCValid() || !this.isExpirationDateValid() || !this.isCvvValid()){
        return true;
      }
    }

    return false;
  }

  checkout() {
    this.canCheckout = false;
    this.referenceNumber = uuid();

    if (this.cartTotal > 0) {
      let data = {
        referenceNumber: this.referenceNumber,
        amount: this.cartTotal,
        total: this.cartTotal,
        creditCardNumber: this.fields.cardNumber.value.replace(/ /g,''),
        expirationMonth: this.fields.expirationMonth.value,
        expirationYear: this.fields.expirationYear.value,
        securityCode: this.fields.cvv.value,
        firstName: this.fields.firstName.value,
        lastName: this.fields.lastName.value,
      };

      this.paymentsResource.create(data).then(
        (payment) => {
          if (payment.confirmation_num) {
            this.getBatch().then((batch) => {
              let BusinessLicense = Object.assign({}, this.cart[0]);
              this.createReceipt(batch).then((receipt) => {
                // this.saveFees(this.fees.splice(0), receipt).then( () => {

                this.saveReceiptItems(receipt, this.cart.splice(0)).then(() => {
                  this.saveTender(receipt, payment.confirmation_num).then(() => {
                    this.completeReceipt(receipt).then(() => {
                      this.cartService.clear();

                      this.currentTownService.get().then((town) => {
                        this.documentsService.updateDocuments();

                        this.router.navigate([
                          town.slug,
                          'mybusiness',
                          BusinessLicense.businessLicense.businessId,
                          'licenses',
                          BusinessLicense.businessLicenseId,
                          'receipt',
                        ]);
                      });
                    });
                  });
                });
                // })
              });
            });
          } else if (payment.error_code) {
            this.canCheckout = true;

            this.checkoutError = payment;
          }
        },
        (e) => {
          this.canCheckout = true;

          this.checkoutError = e.error;
        }
      );
    } else {
      // Complete checkout for cart with 0 total in order to issue business license 
      this.getBatch().then((batch) => {
        let BusinessLicense = Object.assign({}, this.cart[0]);
        this.createReceipt(batch).then((receipt) => {
          this.saveReceiptItems(receipt, this.cart.splice(0)).then(() => {
            this.completeReceipt(receipt).then(() => {
              this.cartService.clear();
              this.currentTownService.get().then((town) => {
                this.documentsService.updateDocuments();

                this.router.navigate([
                  town.slug,
                  'mybusiness',
                  BusinessLicense.businessLicense.businessId,
                  'licenses',
                  BusinessLicense.businessLicenseId,
                  'receipt',
                ]);
              });
            });
          });
        });
      });
    }
  }

  getBatch() {
    return new Promise((resolve, reject) => {
      var params = new ResourceParams({
        filters: [
          [
            {
              property: 'referenceDate',
              operator: 'eq',
              value: moment().format('YYYY-MM-DD'),
            },
            {
              property: 'paymentSourceId',
              operator: 'eq',
              value: this.paymentSource.id,
            },
            {
              property: 'status',
              operator: 'eq',
              value: 'Open',
            },
          ],
        ],
      });

      this.batchesResource.get(params).then((batches) => {
        if (batches.items.length > 0) {
          resolve(batches.items[0]);
        } else {
          this.createBatch().then((batch) => {
            resolve(batch);
          });
        }
      });
    });
  }

  createBatch() {
    return new Promise((resolve, reject) => {
      let params = new Batch({
        referenceDate: moment().format('YYYY-MM-DD'),
        paymentSourceId: this.paymentSource.id,
        status: 'Open',
        createdBy: this.user.id,
      });

      this.batchesResource.create(params).then((batch) => {
        resolve(batch);
      });
    });
  }

  createReceipt(batch) {
    return new Promise((resolve, reject) => {
      let params = {
        userId: this.user.id,
        batchId: batch.id,
        payorId: this.user.id,
        showComment: false,
        transactionComment: 'processed through public portal',
        total: this.cartTotal,
        tendered: this.cartTotal,
        change: 0,
      };

      this.receiptsResource.create(params).then((data) => {
        resolve(data);
      });
    });
  }

  completeReceipt(receipt) {
    return new Promise((resolve) => {
      const params = {
        urlModifiers: {
          id: receipt.id,
        },
        status: 'Active',
      };

      this.receiptsResource.put(params).then((receipt) => {
        resolve(receipt);
      });
    });
  }

  saveReceiptItems(receipt, cart) {
    return new Promise((resolve, reject) => {
      let params = {
        urlModifiers: {
          receiptId: receipt.id,
        },
        receiptId: receipt.id,
        chargeItemId: cart[0].itemId,
        cost: cart[0].cost,
        quantity: cart[0].quantity,
        permitId: cart[0].permitId,
        businessLicenseId: cart[0].businessLicense
          ? cart[0].businessLicense.id
          : undefined,
      };

      this.receiptItemsResource.create(params).then((data) => {
        cart.shift();

        if (cart.length > 0) {
          this.saveReceiptItems(receipt, cart).then(() => {
            resolve();
          });
        } else {
          resolve();
        }
      });
    });
  }

  saveTender(receipt, confirmationNumber) {
    return new Promise((resolve, reject) => {
      let params = {
        urlModifiers: {
          batchId: receipt.batchId,
        },
        batchId: receipt.batchId,
        receiptId: receipt.id,
        tenderId: this.tender.id,
        amount: this.cartTotal,
        confirmationNumber: confirmationNumber,
        referenceNumber: this.referenceNumber
      };

      this.receiptTendersResource.create(params).then((data) => {
        resolve();
      });
    });
  }

  // saveFees(array, receipt){

  //   return new Promise((resolve) => {

  //     if(array.length == 0){
  //       resolve();
  //     }

  //     const data = {
  //       urlModifiers: {
  //         receiptId: receipt.id
  //       },
  //       receiptId: receipt.id,
  //       feeId: array[0].id,
  //       amount: array[0].amount
  //     }

  //     this.receiptFeesResource.create( data ).then( fee => {
  //       array.shift();

  //       if(array.length > 0){
  //         return this.saveFees(array, receipt).then(() => {
  //           resolve();
  //         });
  //       }else{
  //         resolve();
  //       }
  //     })
  //   })
  // }
}
